import React  from 'react';

import UpdateProfileImageContent from "../content/UpdateProfileImageContent";

import Navbar2 from "../inc/Navbar2";
import Footer from "../inc/Footer";

function UpdateProfileImage() {
    return(
        <>
        <Navbar2 />
        <UpdateProfileImageContent/>
        <Footer />        
        </>
    );
}

export default UpdateProfileImage;