import React  from 'react';

import DashboardContent from "../content/DashboardContent";

import Navbar2 from "../inc/Navbar2";
import Footer from "../inc/Footer";

function Dashboard() {
    return(
        <>
        <Navbar2 />
        <DashboardContent/>
        <Footer />        
        </>
    );
}

export default Dashboard;