
import React, {useState, useEffect} from "react";

import {Link, useNavigate } from 'react-router-dom';

import DashboardLeftNav from "../inc/DashboardLeftNav";
import axios from "axios";
axios.defaults.baseURL='https://suvivah.org/suvivah/api/';



function MatchingProfileContent() {
    return(
        <>

<div className="devider"></div>


<section className="register-row">
<div className="container">
    <div className="row">
        <div className="col-md-3">

        

            <DashboardLeftNav />


        







        </div>
        <div className="col-md-9">
            <h2 className="pb-3">Dashboard</h2>
            

        <div class="register_box">
            <div class="row mb-3">
                <div className="col-md-12">
                    <h4>Matching Profile</h4>
                </div>
                
            </div>


            
        </div>

        </div>
    </div>
</div>
</section>


        </>
    );
}

export default MatchingProfileContent;