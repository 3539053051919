import React  from 'react';

import CommunicationDetailsContent from "../content/CommunicationDetailsContent";

import Navbar2 from "../inc/Navbar2";
import Footer from "../inc/Footer";

function CommunicationDetails() {
    return(
        <>
        <Navbar2 />
        <CommunicationDetailsContent/>
        <Footer />        
        </>
    );
}

export default CommunicationDetails;