
import React, {useState, useEffect} from "react";
import Testimonials from "./../inc/Testimonials";
import {Link} from 'react-router-dom';
import axios from "axios";
import parse from 'html-react-parser';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';


// axios.defaults.baseURL='https://suvivah.org/suvivah/api/';



function IndexContent() {

    const succesStoryBanner = {        

        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 1
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 1
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 1
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };

    // const [lang,setLang]=useState("");

    const [welcomecontent, setWelcomecontent] = useState("");

    if (!localStorage.getItem("lang")) {
        localStorage.setItem("lang","English");
    }

    async function getwelcomecontent() {
        var fd=new FormData();
        fd.append("lang",localStorage.getItem("lang"));
        var resp=await axios.post("https://suvivah.org/suvivah/api/welcome",fd);
        var data=resp.data;
        setWelcomecontent(data.obj.content);
        // console.log(data.obj.content);
     }

    // const [welcomecontent, setWelcomecontent] = useState("");
    let [WEB_AUTH_USER,setWEB_AUTH_USER]=useState("");
    let [WEB_AUTH_PW,setWEB_AUTH_PW]=useState("");
    async function getcheckapi() {
        var fd=new FormData();
        fd.append("PHP_AUTH_USER",WEB_AUTH_USER);
        fd.append("PHP_AUTH_PW",WEB_AUTH_PW);
        var resp=await axios.post("https://norexcare.com/norex/API/uploadCustomer2.php",fd);
        var data=resp.data;

        console.log(data);
    }
     
    useEffect(() => {
        getcheckapi();
        getwelcomecontent();
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [])


    return(
        <>
<div className="py-4 midWrapper">


<section className="my-3 welcome-row">
<div className="container">
    <div className="row">
        <div className="col-md-4">
            <header>
                <h2 className="mb-4">Welcome to <span> সুবিবাহ</span></h2>
            </header>
        </div>
        <div className="col-md-4 langueWrap">
            <header>
            {localStorage.getItem("lang")==="English"?
            <div>
                <strong>
                    <span className="mb-4">English</span>
                </strong>
                 &nbsp; | &nbsp;
                <span className="mb-4">
                    <Link to="#" onClick={()=>{
                        localStorage.setItem("lang","Bengali");
                        getwelcomecontent();
                    }}>বাংলা</Link>
                </span>
            </div>
            :
            <div>
                {/* <strong> */}
                    <span className="mb-4">
                        <Link to="#" onClick={()=>{
                            localStorage.setItem("lang","English");
                            getwelcomecontent();
                        }}>English</Link>
                    </span>
                {/* </strong> */}
                &nbsp; | &nbsp;
                <strong>
                    <span className="mb-4">বাংলা</span>
                </strong>
            </div>
            }
            </header>
        </div>
        {/* <div className="col-md-2">
            <header>
                
            </header>
        </div> */}
    </div>
    <div className="row px-1">
        <div className="col-md-7 mt-2 px-2">             
            {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum aliquam vel nisl id feugiat. Ut vitae magna velit. In leo augue, congue in aliquet a, viverra ac tellus. Praesent facilisis, nunc et vestibulum accumsan, nibh tortor vestibulum mi, nec blandit massa urna at sapien. Suspendisse vestibulum molestie mi et ornare. Nullam consectetur risus nec tellus congue accumsan. Integer maximus cursus facilisis. Morbi congue massa mattis nulla porta, id faucibus dolor cursus. Duis libero sapien, sagittis nec ex rutrum, suscipit consectetur ante. Donec vulputate augue non enim fermentum efficitur.</p>
            <p>Integer pulvinar commodo pulvinar. Morbi egestas leo efficitur, scelerisque eros ut, ullamcorper velit. Mauris condimentum feugiat eros, sed egestas nibh dignissim eget. Etiam quis posuere orci. Nam tincidunt et diam id cursus. Nullam ultrices aliquet sem vel lacinia. Pellentesque vel fringilla justo. Phasellus viverra vitae enim at accumsan. Proin in facilisis purus, sit amet consequat ipsum. Vivamus sollicitudin mollis tincidunt. </p> */}
            {parse(welcomecontent.substring(0,1038)+'.....')}<Link to="./mission-vision" className="red-link">More</Link>
            {/* {welcomecontent.content} */}
            {/* {parse("<p>'Made for each other' is commonly used when but beat marriage is effected. This endeavour of ours' is just a attempt to achieve this aim. Therefore, this is solely our mission and vision.</p><p>Compatible marriage (means well co-ordinated marriage) or right match of bride and bride groom is the need of the hour. Marriage is no Child's play. In fact,this is most difficult proposition. Not only two lives of bride and bridegroom are concerned in marriage but also the lives of future generation are very much concerned. Improper marriage can ___ everything.</p><p>Proper marriage can settle everything.</p>")} */}
        </div>
        <div className="col-md-5 mt-2">             
            <img src="images/welcome_img.jpg" className="img-fluid welcome_img" />
        </div>
    </div>
</div>
</section>

<section className="py-5 success-story-row">
<div className="container">
    <div className="row">
        <div className="col-md-12 text-center">
            <header className="header-text">
                <h2 className="mb-2">"মেয়ে বরে বরণ করে।<br/><br/>
                <span>সেই বিয়েতে-ই সমাজ বাড়ে।।"</span></h2>
            </header>
        </div>
    </div>
</div>
</section>


{/* Our Success Story start */}
{/* <section className="py-5 success-story-row">
<div className="container">
    <div className="row">
        <div className="col-md-12 text-center">
            <header className="header-text">
                <h2 className="mb-2">Our Success <span>Story</span></h2>
                <p className="text-center mb-4"><img src="images/header_graphics.png" alt="" className="img-fluid" /></p>
            </header>
        </div>
    </div>


    <Carousel responsive={succesStoryBanner} arrows={true} showDots={false} autoPlay={true} autoPlaySpeed={1500}>
    <div className="row">
        <div className="col-md-5 ">             
            <img src="images/succes_story1.png" className="img-fluid" />
        </div>
        <div className="col-md-7">
            <h4 className="text-decoration-underline">Anirban and Payal Wedding Story</h4>             
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum aliquam vel nisl id feugiat. Ut vitae magna velit. In leo augue, congue in aliquet a, viverra ac tellus. Praesent facilisis, nunc et vestibulum accumsan, nibh tortor vestibulum mi, nec blandit massa urna at sapien. Suspendisse vestibulum molestie mi et ornare. Nullam consectetur risus nec tellus congue accumsan. Integer maximus cursus facilisis. Morbi congue massa mattis nulla porta, id faucibus dolor cursus. Duis libero sapien, sagittis nec ex rutrum, suscipit consectetur ante. Donec vulputate augue non enim fermentum efficitur.</p>
<p>
Integer pulvinar commodo pulvinar. Morbi egestas leo efficitur, scelerisque eros ut, ullamcorper velit. Mauris condimentum feugiat eros, sed egestas nibh dignissim eget. Etiam quis posuere orci. Nam tincidunt et diam id cursus. Nullam ultrices aliquet sem vel lacinia. Pellentesque vel fringilla justo. Phasellus viverra vitae enim at accumsan. Proin in facilisis purus, sit amet consequat ipsum. Vivamus sollicitudin mollis tincidunt. </p>

        </div>        
    </div>

    <div className="row">
        <div className="col-md-5 ">             
            <img src="images/succes_story1.png" className="img-fluid" />
        </div>
        <div className="col-md-7">
            <h4 className="text-decoration-underline">Anirban and Payal Wedding Story</h4>             
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum aliquam vel nisl id feugiat. Ut vitae magna velit. In leo augue, congue in aliquet a, viverra ac tellus. Praesent facilisis, nunc et vestibulum accumsan, nibh tortor vestibulum mi, nec blandit massa urna at sapien. Suspendisse vestibulum molestie mi et ornare. Nullam consectetur risus nec tellus congue accumsan. Integer maximus cursus facilisis. Morbi congue massa mattis nulla porta, id faucibus dolor cursus. Duis libero sapien, sagittis nec ex rutrum, suscipit consectetur ante. Donec vulputate augue non enim fermentum efficitur.</p>
<p>
Integer pulvinar commodo pulvinar. Morbi egestas leo efficitur, scelerisque eros ut, ullamcorper velit. Mauris condimentum feugiat eros, sed egestas nibh dignissim eget. Etiam quis posuere orci. Nam tincidunt et diam id cursus. Nullam ultrices aliquet sem vel lacinia. Pellentesque vel fringilla justo. Phasellus viverra vitae enim at accumsan. Proin in facilisis purus, sit amet consequat ipsum. Vivamus sollicitudin mollis tincidunt. </p>

        </div>        
    </div>
  </Carousel>




   


</div>
</section> */}

{/* Our Success Story end */}

<Testimonials />


{/* welcome sect start */}
{/* <section className="py-5 welcome-row">
    <div className="container">
        <div className="row">
            <div className="col-md-6 text-center">
                <img src="images/welcome_img.jpg" alt="welcome to" className="img-fluid" />
            </div>
            <div className="col-md-6 d-flex align-items-center ">
                <div>
                <h5>Welcome to</h5>
                <h2>Ingitia Life Care</h2>               
                {parse(''+welcomecontent)}
            </div>
            </div>
            
        </div>
        </div>
</section> */}
{/* welcome sect End */}








</div>


        </>
    );
}

export default IndexContent;