import React, {useState, useEffect} from "react";
import {Link, useNavigate } from 'react-router-dom';
import axios from "axios";
import DashboardLeftNav from "../inc/DashboardLeftNav";



function MaternalBibliographyContent() {
    let navigate = useNavigate();

    const [progress,setProgress]=useState(0);
    const [loader,setLoader]=useState(false);

    let [maternalinformation,setMaternalinformation]=useState('');

    let [castarr,setCastarr]=useState([]);
    let [tribearr,setTribearr]=useState([]);
    let[clanarr,SetClanarr]=useState([]);
    



    let [healthdetailsarr,setHealthdetailsarr]=useState("");
    
    
    let [maleoccupation,setMaleoccupation]=useState([]);


    async function showmaternalbibliography() {
        // setProgress(100);
        // setLoader(true);

        var resp2=await axios.get("https://suvivah.org/suvivah/api/reg_cast");
        var data2=resp2.data;
        setCastarr(data2);
      
        var fd=new FormData();
        fd.append("pid",localStorage.getItem("pid"));
        var resp=await axios.post("https://suvivah.org/suvivah/api/maternalinformation",fd);
        var data=resp.data;
        console.log(data);
        setMaternalinformation(data.row);

        setMaternalgrandfathername(data.row.maternal_grandfather_name);
        setGreatmaternalgrandfathername(data.row.great_maternal_grandfather_name);
        setMaternalgrandfatheroccupation(data.row.maternal_grandfather_occupation);
        setGreatmaternalgrandfatheroccupation(data.row.great_maternal_grandfather_occupation);
        setMcastid(data.row.mother_cast);
        setTribearr(data.tribe);
        SetClanarr(data.clan);

        setMaleoccupation(data.maleoccupation);

        setMgcastid(data.row.maternal_grandfather_cast);

        setMgflifeexpectancy(data.health.maternal_grandfather_life_expectancy);
        setGmgflifeexpectancy(data.health.great_maternal_grandfather_life_expectancy);

        setHealthdetailsarr(data.health);  

        setMgfcdisease(data.health.maternal_grandfather_chronic_disease);
        setMgfcdiseasedetails(data.health.maternal_grandfather_chronic_disease_details);

        if (data.health.maternal_grandfather_chronic_disease=='No') {
            setMgfcdiseaseyes(false);
            setMgfcdiseaseno(true);

            setFcd(true);
        } else {
            setMgfcdiseaseyes(true);
            setMgfcdiseaseno(false);

            setFcd(false);
        }
        


        setGmgfcdisease(data.health.great_maternal_grandfather_chronic_disease);
        setGmgfcdiseasedetails(data.health.great_maternal_grandfather_chronic_disease_details);

        if (data.health.great_maternal_grandfather_chronic_disease=='No') {
            setGmgfcdiseaseyes(false);
            setGmgfcdiseaseno(true);

            setGpgfcd(true);
        } else {
            setGmgfcdiseaseyes(true);
            setGmgfcdiseaseno(false);

            setGpgfcd(false);
        }


      
        

        setGmgcastid(data.row.great_maternal_grandfather_cast);
        setMtribeid(data.row.mother_tribe);

        setMgftribeid(data.row.maternal_grandfather_tribe);
        setGmgftribeid(data.row.great_maternal_grandfather_tribe);
        setMclan(data.row.mother_clan);
        setMgfclan(data.row.maternal_grandfather_clan);
        setGmgfclan(data.row.great_maternal_grandfather_clan);
        
        
    }

    //UPDATE DATA
    let [maternalgrandfathername,setMaternalgrandfathername]=useState("");
    let [greatmaternalgrandfathername,setGreatmaternalgrandfathername]=useState("");
    let [maternalgrandfatheroccupation,setMaternalgrandfatheroccupation]=useState("");
    let [greatmaternalgrandfatheroccupation,setGreatmaternalgrandfatheroccupation]=useState("");

    let [mcastid,setMcastid]=useState("")
    let [mtribeid,setMtribeid]=useState("");
    let [mclan,setMclan]=useState("");

    let [mgcastid,setMgcastid]=useState("");
    let [gmgcastid,setGmgcastid]=useState("");
    
    let [mgftribeid,setMgftribeid]=useState("");
    let [gmgftribeid,setGmgftribeid]=useState("");
    
    let [mgfclan,setMgfclan]=useState("");
    let [gmgfclan,setGmgfclan]=useState("");

    let [mgflifeexpectancy,setMgflifeexpectancy]=useState("");
    let [gmgflifeexpectancy,setGmgflifeexpectancy]=useState("");

    let[mgfcdisease,setMgfcdisease]=useState("");
    let[mgfcdiseasedetails,setMgfcdiseasedetails]=useState("");

    let [mgfcdiseaseyes,setMgfcdiseaseyes]=useState(false);
    let [mgfcdiseaseno,setMgfcdiseaseno]=useState(false);

    let [fcd,setFcd]=useState(false);

    let[gmgfcdisease,setGmgfcdisease]=useState("");
    let[gmgfcdiseasedetails,setGmgfcdiseasedetails]=useState("");

    let [gmgfcdiseaseyes,setGmgfcdiseaseyes]=useState(false);
    let [gmgfcdiseaseno,setGmgfcdiseaseno]=useState(false);

     let [gpgfcd,setGpgfcd]=useState(false);
    
    let [ismsg,setIsmsg]=useState(false);

    async function updatematernaldata() {
        setIsmsg(false);
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });

        var fd=new FormData();
        fd.append("pid",localStorage.getItem("pid"));
        fd.append("maternalgrandfathername",maternalgrandfathername);
        fd.append("greatmaternalgrandfathername",greatmaternalgrandfathername);
        fd.append("maternalgrandfatheroccupation",maternalgrandfatheroccupation);
        fd.append("greatmaternalgrandfatheroccupation",greatmaternalgrandfatheroccupation);
        fd.append("mcastid",mcastid);
        fd.append("mtribeid",mtribeid);
        fd.append("mclanid",mclan);
        fd.append("mgcastid",mgcastid);
        fd.append("gmgcastid",gmgcastid);
        fd.append("mgftribeid",mgftribeid);
        fd.append("gmgftribeid",gmgftribeid);
        fd.append("mgfclanid",mgfclan);
        fd.append("gmgfclanid",gmgfclan);
        fd.append("mgflifeexpectancy",mgflifeexpectancy);
        fd.append("gmgflifeexpectancy",gmgflifeexpectancy);
        fd.append("mgfcdisease",mgfcdisease);
        fd.append("mgfcdiseasedetails",mgfcdiseasedetails);
        fd.append("gmgfcdisease",gmgfcdisease);
        fd.append("gmgfcdiseasedetails",gmgfcdiseasedetails);
        var resp=await axios.post("https://suvivah.org/suvivah/api/updatematernaldata",fd);
        var data=resp.data;

        setIsmsg(true);
    }


    useEffect(()=>{
        if (!localStorage.getItem("pid")) {
            let path=`/login`;
            navigate(path);
        }
        showmaternalbibliography();
    },[])


    



    return(
        <>

<div className="devider"></div>


<section className="register-row">
<div className="container">
    <div className="row">
        <div className="col-md-3">      

           <DashboardLeftNav />

        </div>
        <div className="col-md-9">
            <h2 className="pb-3">Dashboard</h2>
            

        <div class="register_box">
            <div class="row mb-3">
                <div className="col-md-12">
                    {ismsg?<div className="text text-success text-center">Maternal Details Updated!!</div>:''}
                    <h4>Maternal Bibliography</h4>
                </div>
             </div>

             <div class="row mb-3">   
                <div class="col-md-6">
                <label className="display-block w-100">Maternal Grandfather Name:</label>
                <input type="text" class="form-control small" placeholder="Maternal Grandfather Name" value={maternalgrandfathername} onChange={(ev)=>{
                    setMaternalgrandfathername(ev.target.value);
                }} /></div>
                <div class="col-md-6">
                <label className="display-block w-100">Great Maternal Grandfather Name:</label>
                <input type="text" class="form-control"  placeholder="Great Maternal Grandfather Name" value={greatmaternalgrandfathername} onChange={(ev)=>{
                    setGreatmaternalgrandfathername(ev.target.value);
                }} /></div>
            </div>

            <div class="row mb-3">   
                <div class="col-md-6">
                <label className="display-block w-100">Maternal Grandfather Occupation:</label>
                <select className="form-select" onChange={(ev)=>{
                    setMaternalgrandfatheroccupation(ev.target.value);
                    // if (ev.target.value.length>0) {
                    //     setFoccuerr(false);
                    // }
                }} value={maternalgrandfatheroccupation} >
                <option value={""}>--Select Maternal Grandfather Occupation--</option> 
                {maleoccupation.map((e)=>
                <option key={e.occupation_id} value={e.occupation_id}>{e.occupation_name}</option>
                )}
                </select>
                {/* <input type="text" class="form-control" placeholder="Maternal Grandfather Occupation" value={maternalgrandfatheroccupation} onChange={(ev)=>{
                    setMaternalgrandfatheroccupation(ev.target.value);
                }} /> */}
                </div>
                <div class="col-md-6">
                <label className="display-block w-100">Great Maternal Grandfather Occupation:</label>
                <select className="form-select" onChange={(ev)=>{
                    setGreatmaternalgrandfatheroccupation(ev.target.value);
                    // if (ev.target.value.length>0) {
                    //     setFoccuerr(false);
                    // }
                }} value={greatmaternalgrandfatheroccupation} >
                <option value={""}>--Select Great Maternal Grandfather Occupation--</option> 
                {maleoccupation.map((e)=>
                <option key={e.occupation_id} value={e.occupation_id}>{e.occupation_name}</option>
                )}
                </select>
                {/* <input type="text" class="form-control" placeholder="Great Maternal Grandfather Occupation" value={greatmaternalgrandfatheroccupation} onChange={(ev)=>{
                    setGreatmaternalgrandfatheroccupation(ev.target.value);
                }} /> */}
                </div>
            </div>





            {/* Maternal Grandfather Chronic Disease */}

            <div class="row mb-3">
                <div class="col-md-4">
                <label className="display-block w-100">Mother Cast</label>
                    <select class="form-select" value={mcastid} onChange={(ev)=>{
                            setMcastid(ev.target.value);
                        }}  >
                        <option value={""}>--Select Cast--</option>
                        {castarr.map((e)=>
                            // <optgroup label={e.pcast_name} key={e.pcast_id}>
                                <option key={e.cast_id} value={e.cast_id}>{e.cast_name}</option>
                            // </optgroup>
                        )}
                    </select>
                </div>
                <div class="col-md-4">
                <label className="display-block w-100">Mother Tribe</label>
                    <select class="form-select" value={mtribeid} onChange={(ev)=>{
                            setMtribeid(ev.target.value);
                        }}  >
                        <option value={""}>--Select Tribe--</option>
                        {tribearr.map((t)=>
                            <option value={t.tribe_id} key={t.tribe_id}>{t.tribe_name}</option>
                            )}
                    </select>
                </div>
                <div class="col-md-4">
                <label className="display-block w-100">Mother Clan</label>
                    {/* <select class="form-select" value={mclan} onChange={(ev)=>{
                            setMclan(ev.target.value);
                        }} >
                        <option value={""}>--Select Clan--</option>
                        {clanarr.map((t)=>
                            <option value={t.clan_id} key={t.clan_id}>{t.clan_name}</option>
                            )}
                    </select> */}
                    <input type="text" class="form-control" placeholder="Clan Name" onChange={(ev)=>{
                        setMclan(ev.target.value);
                    }} value={mclan} />
                </div>
            </div>

            

            <div class="row mb-3">
                <div class="col-md-6">
                    <label className="display-block w-100">Maternal Grandfather Caste</label>
                    <select class="form-select" onChange={(ev)=>{
                            setMgcastid(ev.target.value);
                        }} value={mgcastid} >
                        <option value={""}>--Select Cast--</option>
                        {castarr.map((e)=>
                            // <optgroup label={e.pcast_name} key={e.pcast_id}>
                                <option key={e.cast_id} value={e.cast_id}>{e.cast_name}</option>
                            // </optgroup>
                        )}
                    </select>
                </div>
                <div class="col-md-6">
                    <label className="display-block w-100">Greate Maternal Grandfather Caste</label>
                    <select class="form-select" onChange={(ev)=>{
                            setGmgcastid(ev.target.value);
                        }} value={gmgcastid} >
                        <option value={""}>--Select Cast--</option>
                        {castarr.map((e)=>
                            // <optgroup label={e.pcast_name} key={e.pcast_id}>
                                <option key={e.cast_id} value={e.cast_id}>{e.cast_name}</option>
                            // </optgroup>
                        )}
                    </select>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-md-6">
                <label className="display-block w-100">Maternal Grandfather Tribe</label>
                    <select class="form-select" value={mgftribeid} onChange={(ev)=>{
                            setMgftribeid(ev.target.value);
                        }}>
                        <option value={""}>--Select any one--</option>
                        {tribearr.map((t)=>
                            <option value={t.tribe_id} key={t.tribe_id}>{t.tribe_name}</option>
                        )}
                    </select>
                </div>
                <div class="col-md-6">
                <label className="display-block w-100">Greate Maternal Grandfather Tribe</label>
                    <select class="form-select" value={gmgftribeid} onChange={(ev)=>{
                            setGmgftribeid(ev.target.value);
                        }} >
                        <option value={""}>--Select Tribe--</option>
                        {tribearr.map((t)=>
                            <option value={t.tribe_id} key={t.tribe_id}>{t.tribe_name}</option>
                            )}
                    </select>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-md-6">
                <label className="display-block w-100">Maternal Grandfather Clan</label>
                    {/* <select class="form-select" value={mgfclan} onChange={(ev)=>{
                            setMgfclan(ev.target.value);
                        }}>
                        <option value={""}>--Select Clan--</option>
                        {clanarr.map((t)=>
                            <option value={t.clan_id} key={t.clan_id}>{t.clan_name}</option>
                            )}
                    </select> */}
                    <input type="text" class="form-control" placeholder="Grandfather's Clan Name" onChange={(ev)=>{
                        setMgfclan(ev.target.value);
                    }} value={mgfclan} />
                </div>
                <div class="col-md-6">
                    <label className="display-block w-100">Greate Maternal Grandfather Clan</label>
                    {/* <select class="form-select" value={gmgfclan} onChange={(ev)=>{
                            setGmgfclan(ev.target.value);
                        }} >
                        <option value={""}>--Select any one--</option>
                        {clanarr.map((t)=>
                            <option value={t.clan_id} key={t.clan_id}>{t.clan_name}</option>
                            )}
                    </select> */}
                    <input type="text" class="form-control" placeholder="Great Grandfather's Clan Name" onChange={(ev)=>{
                        setGmgfclan(ev.target.value);
                    }} value={gmgfclan} />
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-md-6">
                    <label className="display-block w-100">Maternal Grandfather Life Expectancy</label>
                    <select class="form-select" onChange={(ev)=>{
                            setMgflifeexpectancy(ev.target.value);
                        }} value={mgflifeexpectancy}>
                        <option value={""}>--Select any one--</option>
                        <option value={"Still Alive"}>Still Alive</option>
                        {[...Array(133)].map((x, i) =>
                           
                           <option value={i+18} key={i}>{18+ i} Years</option>
                       )}
                    </select>
                </div>
                <div class="col-md-6">
                <label className="display-block w-100">Greate Maternal Grandfather Life Expectancy</label>
                {/* <div class="row">
                    <div class="col-md-6"> */}
                    <select class="form-select" onChange={(ev)=>{
                            setGmgflifeexpectancy(ev.target.value);
                        }} value={gmgflifeexpectancy}>
                        <option value={""}>--Select any one--</option>
                        <option value={"Still Alive"}>Still Alive</option>
                        {[...Array(133)].map((x, i) =>                           
                           <option value={i+18} key={i}>{18+ i} Years</option>
                       )}
                    </select>
                {/* </div>
                    </div> */}
                </div>
                
            </div>

            <div class="row mb-3">
                <div class="col-md-12">                    
                <label class="display-block pb-3 me-3">Maternal Grandfather Chronic Disease</label>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="fatherschronic" id="fatherschronic1" value="Yes" checked={mgfcdiseaseyes} onChange={(ev)=>{
                        setMgfcdisease(ev.target.value);
                        setMgfcdiseaseyes(true);
                        setMgfcdiseaseno(false);
                        setFcd(false);
                        setMgfcdiseasedetails(healthdetailsarr.maternal_grandfather_chronic_disease_details);
                       
                    }} />
                    <label class="form-check-label" for="fatherschronic1">Yes</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="fatherschronic" id="fatherschronic2" value="No" checked={mgfcdiseaseno} onChange={(ev)=>{
                        setMgfcdisease(ev.target.value);
                        setMgfcdiseaseyes(false);
                        setMgfcdiseaseno(true);
                        setFcd(true);
                        setMgfcdiseasedetails("");
                    }}  />
                    <label class="form-check-label" for="fatherschronic2">No</label>
                </div>
                </div>
                <div class="col-md-12">
                    <textarea class="form-control" rows="1" id="comment" name="text" placeholder="Maternal Grandfather Chronic Disease Details" value={mgfcdiseasedetails} onChange={(ev)=>{
                    setMgfcdiseasedetails(ev.target.value);
                    
                }} disabled={fcd}></textarea>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-md-12">                    
                <label class="display-block pb-3 me-3">Greate Maternal Grandfather Chronic Disease</label>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="aa" id="fatherschronic3" value="Yes" checked={gmgfcdiseaseyes} onChange={(ev)=>{
                        setGmgfcdisease(ev.target.value);
                        setGmgfcdiseaseyes(true);
                        setGmgfcdiseaseno(false);
                        setGpgfcd(false);
                        setGmgfcdiseasedetails(healthdetailsarr.great_maternal_grandfather_chronic_disease_details);
                       
                    }} />
                    <label class="form-check-label" for="fatherschronic">Yes</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="aa" id="fatherschronic4" value="No" checked={gmgfcdiseaseno} onChange={(ev)=>{
                        setGmgfcdisease(ev.target.value);
                        setGmgfcdiseaseyes(false);
                        setGmgfcdiseaseno(true);
                        setGpgfcd(true);
                        setGmgfcdiseasedetails("");
                    }}  />
                    <label class="form-check-label" for="fatherschronic4">No</label>
                </div>
                </div>
                <div class="col-md-12">
                    <textarea class="form-control" rows="1" id="comment" name="text" placeholder="Greate Maternal Grandfather Chronic Disease Details" value={gmgfcdiseasedetails} onChange={(ev)=>{
                    setGmgfcdiseasedetails(ev.target.value);
                    
                }} disabled={gpgfcd}></textarea>
                </div>
            </div>



            <div class="row">
                <div class="col-md-12 text-center"><button type="button" class="btn btn-red px-5" onClick={updatematernaldata}>Update</button></div>
            </div>

            
        </div>

        </div>
    </div>
</div>
</section>


        </>
    );
}

export default MaternalBibliographyContent;