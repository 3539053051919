
import React, {useState, useEffect} from "react";

import {Link} from 'react-router-dom';
import axios from "axios";
import parse from 'html-react-parser';
axios.defaults.baseURL='https://suvivah.org/suvivah/api/';



function MissionVisionContent() {

    const [welcomecontent, setWelcomecontent] = useState("");

    if (!localStorage.getItem("lang")) {
        localStorage.setItem("lang","English");
    }

    async function getwelcomecontent() {
        var fd=new FormData();
        fd.append("lang",localStorage.getItem("lang"));
        var resp=await axios.post("https://suvivah.org/suvivah/api/welcome",fd);
        var data=resp.data;
        setWelcomecontent(data.obj.content);
        // console.log(data.obj.content);
     }

    // const [welcomecontent, setWelcomecontent] = useState("");

     
     
    useEffect(() => {
        getwelcomecontent();
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [])

    return(
        <>


<div class="devider"></div>
<section class="register-row" style={{background: '#ffd352'}}>
    <div class="container">
        <div class="row px-1">
            {/* <div class="col-md-12">
                <header>
                    <h2 class="mb-4">Mission &amp; Vision</h2>                    
                </header>
            </div> */}
            <div className="col-md-4">
                <header>
                    <h2 className="mb-4">Mission &amp; Vision</h2>
                </header>
            </div>
            <div className="col-md-4 langueWrap">
                <header>
                {localStorage.getItem("lang")==="English"?
                <div>
                    <strong>
                        <span className="mb-4">English</span>
                    </strong>
                    &nbsp; | &nbsp;
                    <span className="mb-4">
                        <Link to="#" onClick={()=>{
                            localStorage.setItem("lang","Bengali");
                            getwelcomecontent();
                        }}>বাংলা</Link>
                    </span>
                </div>
                :
                <div>
                    {/* <strong> */}
                        <span className="mb-4">
                            <Link to="#" onClick={()=>{
                                localStorage.setItem("lang","English");
                                getwelcomecontent();
                            }}>English</Link>
                        </span>
                    {/* </strong> */}
                    &nbsp; | &nbsp;
                    <strong>
                        <span className="mb-4">বাংলা</span>
                    </strong>
                </div>
                }
                </header>
            </div>
        </div>

        <div class="row px-1">
            <div class="col-md-12 mt-2" style={{color: '#660705'}}>
               {parse(welcomecontent)}
            </div>
        </div>


    </div>
</section>


        </>
    );
}

export default MissionVisionContent;