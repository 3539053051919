
import React, {useState, useEffect} from "react";

import axios from "axios";

import {useNavigate } from 'react-router-dom';

import validator from 'validator';



axios.defaults.baseURL='https://suvivah.org/suvivah/api/';




function LoginContent() {
    let navigate = useNavigate();

    let [email,setEmail] = useState("");
    let [phone,setPhone] = useState("");
    let [password,setPassword] = useState("");

    let [emailerr,setEmailerr] = useState(false);
    let [phoneerr,setPhoneerr] = useState(false);
    let [passworderr,setPassworderr] = useState(false);

    let [flagmsg,setFlagmsg]=useState(false);
    let [msg,setMsg]=useState("");
    let [msgcode,setMsgcode]=useState("");
    let [msgcolor,setMsgcolor]=useState("");
    let [btnlogin,setBtnlogin]=useState(false);

    async function loginauth() {
        var flag=1;

        // if (!validator.isEmail(email)) {
        //     setEmailerr(true);
        //     flag=0;
        // }
        if (phone.length<10) {
            setPhoneerr(true);
            flag=0;
        }
        if (password.length<6) {
            setPassworderr(true);
            flag=0;
        }

        if (flag==1) {
            var fd=new FormData();
            // fd.append("email",email);  
            fd.append("phone",phone);     
            fd.append("password",password);           

            var resp=await axios.post("/custlogin",fd);
            var data=resp.data;

            if (data.msgcode==1) {
                setBtnlogin(true);
                window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });
                
                setFlagmsg(true);
                setMsg(data.msg);
                setMsgcolor(data.alert);
                localStorage.setItem("pid",data.profileid);
                setTimeout(() => {
                    let path=`/dashboard`;
                    navigate(path);
                }, 2000);
            } else if (data.msgcode==2) {
                window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });
                setFlagmsg(true);
                setMsg(data.msg);
                setMsgcolor(data.alert);                
            }
        }

    }

    useEffect(()=>{
        if (localStorage.getItem("pid")) {
            let path=`/dashboard`;
            navigate(path);
        }
    },[])

    return(
        <>

        
<div class="devider"></div>

<section class="login-row">
<div class="container">
    <div class="row">
        <div class="col-md-12">
            <header>
                <h2 class="text-center mb-4">Login Now</h2>
            </header>
            
        </div>
    </div>

    {flagmsg?<div className={msgcolor}>{msg}</div>:''}

    <div class="row">
        <div class="col-md-8 offset-md-2">
                <div class="register_box">
            <div class="row mb-3">               
                {/* <div class="col-md-12">
                    <input type="email" class="form-control" id="email" placeholder="Enter Valid Email" name="email" onChange={(ev)=>{
                        setEmail(ev.target.value);
                        if (validator.isEmail(ev.target.value)) {
                            setEmailerr(false);
                        }
                    }} />
                    {emailerr?<div className='text text-danger small'>Valid Email ID Required</div>:''}
                </div> */}

                <div class="col-md-12">
                    <input type="email" class="form-control" maxLength={10} id="email" placeholder="Enter Registered Phone Number" name="email" onChange={(ev)=>{
                        setPhone(ev.target.value);
                        if (ev.target.value.length==10) {
                            setPhoneerr(false);
                        }
                    }} />
                    {phoneerr?<div className='text text-danger small'>Valid Phone Number Required</div>:''}
                </div>
                
            </div>
            

            <div class="row mb-3">
                <div class="col-md-12">
                     <input type="password" class="form-control" placeholder="Enter Valid Password" name="password" onChange={(ev)=>{
                        setPassword(ev.target.value);
                        if (password.length>5) {
                            setPassworderr(false);
                        }
                     }} />
                     {passworderr?<div className='text text-danger small'>Valid Password Required</div>:''}
                </div>
                            
            </div>

            <div class="row">                
                <div class="col-md-12 text-center">
                   <button type="button" class="btn btn-red px-5 t" onClick={loginauth} disabled={btnlogin}>Login</button>
                </div>               
            </div>

            </div>
            <p class="text-center pt-2"><a href="#" class="text-black" onClick={()=>{
                alert("Coming Soon....");
            }}>Forgot Password?</a></p>
        </div>

    </div>
</div>
</section>




        </>
    );
}

export default LoginContent;