
import React, {useState, useEffect} from "react";

import axios from "axios";
import validator from 'validator';

axios.defaults.baseURL='https://suvivah.org/suvivah/api/';



function ContactUsContent() {

    const h4txt={
        marginBottom: '30px',
        textAlign: 'center'
    };

    const errtxt={
        fontSize: 'small'
    };

    let [name,setName]=useState("");
    let [isname,setIsname]=useState(false);

    let [email,setEmail]=useState("");
    let [isemail,setIsemail]=useState(false);

    let [phone,setPhone]=useState("");
    let [isphone,setIsphone]=useState(false);

    let [subject,setSubject]=useState("");
    let [issubject,setIssubject]=useState(false);

    let [content,setContent]=useState("");
    let [iscontent,setIscontent]=useState(false);

    let [isbtn,setIsbtn]=useState(false);

    let [msg,setMsg]=useState("");
    let [msgcolor,setMsgcolor]=useState("");
    let [isalert,setIsalert]=useState(false);

    async function sendmessage() {
        var flag=1;

        if (name.length==0 || name.trim()=="") {
            setIsname(true);
            flag=0;
        }
        if (!validator.isEmail(email)) {
            setIsemail(true);
            flag=0;
        }
        if (phone.length<10 || phone.trim()=="") {
            setIsphone(true);
            flag=0;
        }
        if (subject.length==0 || subject.trim()=="") {
            setIssubject(true);
            flag=0;
        }
        if (content.length==0 || content.trim()=="") {
            setIscontent(true);
            flag=0;
        }

        if (flag==1) {
            var fd=new FormData();
            fd.append("name",name);
            fd.append("email",email);
            fd.append("phone",phone);
            fd.append("subject",subject);
            fd.append("content",content);

            var resp=await axios.post("addcontact",fd);
            var data=resp.data;

            setMsg(data.msg);
            setIsalert(data.sendmsg);
            setMsgcolor(data.msgcolor);

            // alert(data.sendmsg);

            setIsbtn(true);            

            setName("");
            setEmail("");
            setPhone("");
            setSubject("");
            setContent("");
        }
    }

    const [phonelist, setPhonelist] = useState([]);
    const [emaillist, setEmaillist] = useState([]);

    async function contact() {
        var resp=await axios.get("contact");
        var data=resp.data;
        setPhonelist(data.obj);
        setEmaillist(data.obj2);
        // console.log(data.obj);
    }

     
    useEffect(() => {
        contact();
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [])

    return(
        <>


<div class="devider"></div>
<section class="register-row">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <header>
                    <h2 class="mb-4">Contact Us</h2>                    
                </header>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12 register_box mt-4">
               <p>Suvivah.org is eager to help you find your partner at the earliest. Our customer service team will be pleased to assist you anytime you have a query. You can contact our customer service team in one of the following ways.</p>
            </div>

            <div class="col-md-5 px-5 register_box mt-4">
                <h5 className="pt-2" style={{fontWeight: '700'}}>Office Address</h5>
                
                <p><span style={{fontWeight: '700', fontSize: 'large'}}>"SUVIVAH"</span><br/>
                (A Charitable Trust)<br/>
                DEBARATI APARTMENT<br/>
                2nd Floor, Baguiati Market Complex,<br/>
                Kolkata-700059, West Bengal<br/>
                INDIA</p>

                <h5 className="pt-2" style={{fontWeight: '700'}}>Helpline Contact Number</h5>
                {phonelist.map((e)=>
                <p key={e.id}>{e.emoji+' '+(e.name)+' - ('+e.phonecode+')-'+e.phone} <i className="fa fa-phone" onClick={()=>
                    window.open('tel:+'+e.phone)
                }></i></p>
                )}
                <h5 className="pt-4" style={{fontWeight: '700'}}>Helpline Contact Email</h5> 
                {emaillist.map((e)=>
                <p key={e.id}>{e.emoji+' '+(e.name)+' - '+e.email}</p>
                )}
            </div>

            <div class="col-md-7 px-4">
            <div className="container register_box mt-4">
                    <h4 style={h4txt}>Contact Us</h4>
                    <div className='text-center'>{isalert?<div className={msgcolor} style={{margin: 30}}>{msg}</div>:''}</div>
                    <div className="row">
                        <div className="col-md-6 form-group">
                        <input type="text" className="form-control" placeholder="Your Name" onChange={(ev)=>{
                            setName(ev.target.value);
                            if (ev.target.value.length>0 && ev.target.value.trim()!="") {
                                setIsname(false);
                            }
                        }} />
                        {isname?<div className="text text-danger" style={errtxt}>contact name required</div>:''}
                        </div>
                        <div className="col-md-6 form-group mt-3 mt-md-0">
                        <input type="email" className="form-control" placeholder="Your Email" onChange={(ev)=>{
                            setEmail(ev.target.value);
                            if (validator.isEmail(email)) {
                                setIsemail(false);
                            }
                        }} />
                        {isemail?<div className="text text-danger" style={errtxt}>contact email required</div>:''}
                        </div>
                    </div>
                    <div className="form-group mt-3">
                        <input type="tel" minLength={10} maxLength={10} className="form-control" placeholder="Your Phone" onChange={(ev)=>{
                            setPhone(ev.target.value);
                            if (ev.target.value.length==10 && ev.target.value.trim()!="") {
                                setIsphone(false);
                            }
                        }} />
                        {isphone?<div className="text text-danger" style={errtxt}>contact phone required</div>:''}
                    </div>
                    <div className="form-group mt-3">
                        <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" onChange={(ev)=>{
                            setSubject(ev.target.value);
                            if (ev.target.value.length>0 && ev.target.value.trim()!="") {
                                setIssubject(false);
                            }
                        }} />
                        {issubject?<div className="text text-danger" style={errtxt}>subject required</div>:''}
                    </div>
                    <div className="form-group mt-3">
                        <textarea className="form-control" rows="5" placeholder="Message" onChange={(ev)=>{
                            setContent(ev.target.value);
                            if (ev.target.value.length>0 && ev.target.value.trim()!="") {
                                setIscontent(false);
                            }
                        }} />
                        {iscontent?<div className="text text-danger" style={errtxt}>message required</div>:''}
                    </div>
                    {/* <div className="my-3">
                        <div className="loading">Loading</div>
                        <div className="error-message"></div>
                        <div className="sent-message">Your message has been sent. Thank you!</div>
                    </div> */}
                    <div className="form-group mt-3">
                        <div className="text-center"><button className="btn btn-red" onClick={sendmessage} disabled={isbtn} >Send Message</button></div>
                    </div>
                </div>
            </div>
        </div>


    </div>
</section>


        </>
    );
}

export default ContactUsContent;