
import React, {useState, useEffect} from "react";
import {Link, useNavigate } from 'react-router-dom';
import axios from "axios";
import DashboardLeftNav from "../inc/DashboardLeftNav";



function HealthDetailsContent() {
    let navigate = useNavigate();

    // const [progress,setProgress]=useState(0);
    // const [loader,setLoader]=useState(false);

    let [healthdetailsarr,setHealthdetailsarr]=useState('');

    let [userbloodgroup,setUserbloodgroup]=useState('');
    let [userbloodgrouperr,setUserbloodgrouperr]=useState(false);

    let [fatherbloodgroup,setFatherbloodgroup]=useState('');
    let [fatherbloodgrouperr,setFatherbloodgrouperr]=useState(false);

    let [fatherchronicdisease,setFatherchronicdisease]=useState('');
    let [fatherchronicdiseaseerr,setFatherchronicdiseaseerr]=useState(false);

    let [fatherchronicdiseasedetails,setFatherchronicdiseasedetails]=useState('');
    
    let [fatherlifeexpectancy,setFatherlifeexpectancy]=useState('');
    let [fatherlifeexpectancyerr,setFatherlifeexpectancyerr]=useState(false);

    let [motherbloodgroup,setMotherbloodgroup]=useState('');
    let [motherbloodgrouperr,setMotherbloodgrouperr]=useState(false);

    let [motherchronicdisease,setMotherchronicdisease]=useState('');
    let [motherchronicdiseaseerr,setMotherchronicdiseaseerr]=useState(false);

    let [motherchronicdiseasedetails,setMotherchronicdiseasedetails]=useState('');

    let [motherlifeexpectancy,setMotherlifeexpectancy]=useState('');
    let [motherlifeexpectancyerr,setMotherlifeexpectancyerr]=useState(false);

    let [userdiseasereport,setUserdiseasereport]=useState('');
    let [userdiseasereporterr,setUserdiseasereporterr]=useState(false);

    let [fcdyes,setFcdyes]=useState(false);
    let [fcdno,setFcdno]=useState(false);

    let [fcd,setFcd]=useState(false);


    let [mcdyes,setMcdyes]=useState(false);
    let [mcdno,setMcdno]=useState(false);

    let [mcd,setMcd]=useState(false);



    async function showhealthdetails() {
      
        var fd=new FormData();
        fd.append("pid",localStorage.getItem("pid"));
        var resp=await axios.post("https://suvivah.org/suvivah/api/showhealth",fd);
        var data=resp.data;
        
        setHealthdetailsarr(data);
        setUserbloodgroup(data.user_blood_group);

        setFatherbloodgroup(data.father_blood_group);
        setFatherchronicdisease(data.father_chronic_disease);
        setFatherchronicdiseasedetails(data.father_chronic_disease_details);

        if (data.father_chronic_disease=='No') {
            setFcdyes(false);
            setFcdno(true);

            setFcd(true);
        } else {
            setFcdyes(true);
            setFcdno(false);

            setFcd(false);
        }

        setFatherlifeexpectancy(data.father_life_expectancy);
        setMotherbloodgroup(data.mother_blood_group);
        setMotherchronicdisease(data.mother_chronic_disease);
        setMotherchronicdiseasedetails(data.mother_chronic_disease_details);

        if (data.mother_chronic_disease=='No') {
            setMcdyes(false);
            setMcdno(true);

            setMcd(true);
        } else {
            setMcdyes(true);
            setMcdno(false);

            setMcd(false);
        }

        setMotherlifeexpectancy(data.mother_life_expectancy);
        setUserdiseasereport(data.user_disease_report);

    }

    let [ismsg,setIsmsg]=useState(false);

    async function updateheath() {
        var flag=1;
        // alert()

        setIsmsg(false);
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        
        // if (userbloodgroup.length==0) {
        //     setUserbloodgrouperr(true);
        //     flag=0;
        // }
        // if (fatherbloodgroup.length==0) {
        //     setFatherbloodgrouperr(true);
        //     flag=0;
        // }
        // if (motherbloodgroup.length==0) {
        //     setMotherbloodgrouperr(true);
        //     flag=0;
        // }
        // if (fatherchronicdisease.length==0) {
        //     setFatherchronicdiseaseerr(true);
        //     flag=0;
        // }
        // if (motherchronicdisease.length==0) {
        //     setMotherchronicdiseaseerr(true);
        //     flag=0;
        // }        
        // if (fatherlifeexpectancy.length==0) {
        //     setFatherlifeexpectancyerr(true);
        //     flag=0;
        // }
        // if (motherlifeexpectancy.length==0) {
        //     setMotherlifeexpectancyerr(true);
        //     flag=0;
        // }
        // if (userdiseasereport.length==0) {
        //     setUserdiseasereporterr(true);
        //     flag=0;
        // }

        if (flag==1) {

            var fd=new FormData();
            fd.append("pid",localStorage.getItem("pid"));
            fd.append("userbloodgroup",userbloodgroup);
            fd.append("fatherbloodgroup",fatherbloodgroup);
            fd.append("motherbloodgroup",motherbloodgroup);
            fd.append("fatherchronicdisease",fatherchronicdisease);
            fd.append("fcd",fcd);
            fd.append("fatherchronicdiseasedetails",fatherchronicdiseasedetails);
            fd.append("motherchronicdisease",motherchronicdisease);
            fd.append("mcd",mcd);
            fd.append("motherchronicdiseasedetails",motherchronicdiseasedetails);
            fd.append("fatherlifeexpectancy",fatherlifeexpectancy);
            fd.append("motherlifeexpectancy",motherlifeexpectancy);
            fd.append("userdiseasereport",userdiseasereport);
            var resp=await axios.post("https://suvivah.org/suvivah/api/updatehealth",fd);
            var data=resp.data;

            setIsmsg(true);
        }
    }



    useEffect(()=>{
        if (!localStorage.getItem("pid")) {
            let path=`/login`;
            navigate(path);
        }
        showhealthdetails();
    },[])




    return(
        <>

<div className="devider"></div>


<section className="register-row">
<div className="container">
    <div className="row">
        <div className="col-md-3">

       

        <DashboardLeftNav />

        </div>
        <div className="col-md-9">
            <h2 className="pb-3">Dashboard</h2>
            

        <div className="register_box">
            <div className="row mb-3">
                <div className="col-md-12">
                    {ismsg?<div className="text text-success text-center">Health Details Updated!!</div>:''}
                    <h4>Health Details</h4>
                </div>
            </div>

            {/* <div className="row mb-3">
                <div className="col-md-6"><input type="text" className="form-control" placeholder="Enter name" /></div>
                <div className="col-md-6"><input type="email" className="form-control" id="email" placeholder="Enter email" /></div>
            </div> */}

            <div className="row mb-3">
                <div className="col-md-6">
                    <label className="display-block w-100">User Blood Group</label>
                        <select className="form-select"  
                        onChange={(ev)=>{
                            setUserbloodgroup(ev.target.value);
                            if (ev.target.value.length>0) {
                                setUserbloodgrouperr(false);
                            }
                        }} value={userbloodgroup}
                        >
                            <option value={""}>--Select Bloo Group--</option>
                            <option value={"A+"}>A+</option>
                            <option value={"A-"}>A-</option>
                            <option value={"B+"}>B+</option>
                            <option value={"O"}>B-</option>
                            <option value={"O+"}>O+</option>
                            <option value={"O-"}>O-</option>
                            <option value={"AB+"}>AB+</option>
                            <option value={"AB-"}>AB-</option>                            
                        </select> 
                        {userbloodgrouperr?<div className="text text-danger small">User Blood Group Required</div>:''}                   
                 </div> 
                   
            </div>

            <div className="row mb-3">
                <div className="col-md-6">
                    <label className="display-block w-100">Father's Blood Group</label>
                        <select className="form-select" onChange={(ev)=>{
                            setFatherbloodgroup(ev.target.value);
                            if (ev.target.value.length>0) {
                                setFatherbloodgrouperr(false);
                            }
                        }} value={fatherbloodgroup}>
                            <option value={""}>--Select Bloo Group--</option>
                            <option value={"A+"}>A+</option>
                            <option value={"(A-"}>A-</option>
                            <option value={"B+"}>B+</option>
                            <option value={"O"}>B-</option>
                            <option value={"O+"}>O+</option>
                            <option value={"O-"}>O-</option>
                            <option value={"AB+"}>AB+</option>
                            <option value={"AB-"}>AB-</option>                            
                        </select>    
                        {fatherbloodgrouperr?<div className="text text-danger small">Father Blood Group Required</div>:''}                
                 </div> 
                 <div className="col-md-6">
                    <label className="display-block w-100">Mother's Blood Group</label>
                        <select className="form-select" onChange={(ev)=>{
                            setMotherbloodgroup(ev.target.value);
                            if (ev.target.value.length>0) {
                                setMotherbloodgrouperr(false);
                            }
                        }} value={motherbloodgroup}>
                            <option value={""}>--Select Bloo Group--</option>
                            <option value={"A+"}>A+</option>
                            <option value={"(A-"}>A-</option>
                            <option value={"B+"}>B+</option>
                            <option value={"O"}>B-</option>
                            <option value={"O+"}>O+</option>
                            <option value={"O-"}>O-</option>
                            <option value={"AB+"}>AB+</option>
                            <option value={"AB-"}>AB-</option>                            
                        </select>     
                        {motherbloodgrouperr?<div className="text text-danger small">Mother Blood Group Required</div>:''}               
                 </div>   
            </div>

            <div className="row mb-3">
                <div className="col-md-12">                    
                <label className="display-block pb-3 me-3">Father's Chronic Disease</label>
                <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" name="fatherschronic" id="fatherschronic1" value="Yes" checked={fcdyes} onChange={(ev)=>{
                        setFcdyes(true);
                        setFcdno(false);
                        setFcd(false);
                        setFatherchronicdiseasedetails(healthdetailsarr.father_chronic_disease_details);
                        setFatherchronicdiseaseerr(false);
                        setFatherchronicdisease(ev.target.value);
                    }} />
                    <label className="form-check-label" for="fatherschronic1">Yes</label>
                    
                </div>
                <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" name="fatherschronic" id="fatherschronic2" value="No" checked={fcdno} onChange={(ev)=>{
                        setFcdyes(false);
                        setFcdno(true);
                        setFcd(true);
                        setFatherchronicdiseasedetails('');
                        setFatherchronicdiseaseerr(false);
                        setFatherchronicdisease(ev.target.value);
                    }} />
                    <label className="form-check-label" for="fatherschronic2">No</label>
                </div>
                {fatherchronicdiseaseerr?<div className="text text-danger small">Yes/No Required</div>:''}
                </div>
                <div className="col-md-12">
                <textarea className="form-control" rows="1" id="comment" name="text" placeholder="Father's Chronic Disease Details" value={fatherchronicdiseasedetails} onChange={(ev)=>{
                    setFatherchronicdiseasedetails(ev.target.value);
                    
                }} disabled={fcd}/>
                </div>
            </div>

            <div className="row mb-3">
                <div className="col-md-12">                    
                <label className="display-block pb-3 me-3">Mother's Chronic Disease</label>
                <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" name="motherschronic" id="motherschronic1" value="Yes" checked={mcdyes} onChange={(ev)=>{
                        setMcdyes(true);
                        setMcdno(false);
                        setMcd(false);
                        setMotherchronicdiseasedetails(healthdetailsarr.mother_chronic_disease_details);
                        setMotherchronicdiseaseerr(false);
                        setMotherchronicdisease(ev.target.value);
                    }} />
                    <label className="form-check-label" for="motherschronic1">Yes</label>
                </div>
                <div className="form-check form-check-inline">
                    <input className="form-check-input" type="radio" name="motherschronic" id="motherschronic2"  value="No" checked={mcdno} onChange={(ev)=>{
                        setMcdyes(false);
                        setMcdno(true);
                        setMcd(true);
                        setMotherchronicdiseasedetails('');
                        setMotherchronicdiseaseerr(false);
                        setMotherchronicdisease(ev.target.value);
                    }} />
                    <label className="form-check-label" for="motherschronic2">No</label>
                </div>
                {motherchronicdiseaseerr?<div className="text text-danger small">Yes/No Required</div>:''}
                </div>
                <div className="col-md-12">
                    <textarea className="form-control" rows="1" id="comment" name="text" placeholder="Mother's Chronic Disease Details" value={motherchronicdiseasedetails} onChange={(ev)=>{
                    setMotherchronicdiseasedetails(ev.target.value);
                    
                }} disabled={mcd} />
                </div>
            </div>

            <div className="row mb-3">
                <div className="col-md-6">
                    <label className="display-block w-100">User's Health Report</label>
                    <select className="form-select" onChange={(ev)=>{
                            setUserdiseasereport(ev.target.value);
                            if (ev.target.value.length>0) {
                                setUserdiseasereporterr(false);
                            }
                        }} value={userdiseasereport} >
                        <option value={""}>--Select any one--</option>
                        <option value={"Thalassemia"}>Thalassemia</option>
                        <option value={"HIV"}>HIV</option>
                        <option value={"Other Disease"}>Other Disease</option>
                        <option value={"None of The Above"}>None of The Above</option>
                    </select>
                    {userdiseasereporterr?<div className="text text-danger small">Select Report</div>:''}
                </div>
                {/* <div className="col-md-6">
                <label className="display-block w-100">Father's Life Expectancy</label>
                    <select className="form-select" >
                        <option value={""}>--Select any one--</option>
                        <option value={"Still Alive"}>Still Alive</option>
                        <option value={"18y-150y"}>18y-150y</option>
                    </select>
                </div> */}
            </div>

            <div className="row mb-3">
                <div className="col-md-6">
                <label className="display-block w-100">Father's Life Expectancy</label>
                    <select className="form-select" onChange={(ev)=>{
                            setFatherlifeexpectancy(ev.target.value);
                            if (ev.target.value.length>0) {
                                setFatherlifeexpectancyerr(false);
                            }
                        }} value={fatherlifeexpectancy} >
                        <option value={""}>--Select Anyone--</option>
                        <option value={"Still Alive"}>Still Alive</option>

                        {[...Array(133)].map((x, i) =>
                           
                            <option value={i+18} key={i}>{18+ i} Years</option>
                        )}
                        
                    </select>
                    {fatherlifeexpectancyerr?<div className="text text-danger small">Required Field</div>:''}
                </div>
                <div className="col-md-6">
                <label className="display-block w-100">Mother's Life Expectancy</label>
                    <select className="form-select" onChange={(ev)=>{
                            setMotherlifeexpectancy(ev.target.value);
                            if (ev.target.value.length>0) {
                                setMotherlifeexpectancyerr(false);
                            }
                        }} value={motherlifeexpectancy} >
                        <option value={""}>--Select Anyone--</option>
                        <option value={"Still Alive"}>Still Alive</option>
                        {[...Array(133)].map((x, i) =>                           
                           <option value={i+18} key={i}>{18+ i} Years</option>
                       )}
                    </select>
                    {motherlifeexpectancyerr?<div className="text text-danger small">Required Field</div>:''}
                </div>
            </div>            

            <div className="row">
                <div className="col-md-12 text-center"><button type="button" className="btn btn-red px-5" onClick={updateheath}>Update</button></div>
            </div>

            
        </div>

        </div>
    </div>
</div>
</section>


        </>
    );
}

export default HealthDetailsContent;