
import React, {useState, useEffect} from "react";

import axios from "axios";
axios.defaults.baseURL='https://suvivah.org/suvivah/api/';



function AboutContent() {
    return(
        <>

vvvvvvvvvvvv


        </>
    );
}

export default AboutContent;