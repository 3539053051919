import React from 'react';
import {BrowserRouter, Routes,Route} from 'react-router-dom';
import Home from './layout/Home';
import Login from './layout/Login';
import Register from './layout/Register';
import Register2 from './layout/Register2';
import About from './layout/About';
import ContactUs from './layout/ContactUs';
import SuccessStory from './layout/SuccessStory';
import Faqs from './layout/Faqs';
import Thankyou from './layout/Thankyou';

// import Vision from './layout/Vision';
import MissionVision from './layout/MissionVision';

import Dashboard from './layout/Dashboard';
import CommunicationDetails from './layout/CommunicationDetails';
import PersonalInformation from './layout/PersonalInformation';
import HealthDetails from './layout/HealthDetails';
import PaternalBibliography from './layout/PaternalBibliography';
import MaternalBibliography from './layout/MaternalBibliography';
import UpdateProfileImage from './layout/UpdateProfileImage';
import ChangePassword from './layout/ChangePassword';
import MatchingProfile from './layout/MatchingProfile';



import './App.css';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route exact path='/login' element={<Login />} />
          <Route exact path='/register' element={<Register />} />
          <Route exact path='/register2' element={<Register2 />} />
          <Route exact path='/about' element={<About />} />
          <Route exact path='/contact-us' element={<ContactUs />} />
          <Route exact path='/success-story' element={<SuccessStory />} />
          <Route exact path='/faqs' element={<Faqs />} />
          <Route exact path='/thankyou' element={<Thankyou />} />
          <Route exact path='/mission-vision' element={<MissionVision />} />
          {/* <Route exact path='/mission' element={<Mission />} /> */}

          <Route exact path='/dashboard' element={<Dashboard />} />
          <Route exact path='/communication-details' element={<CommunicationDetails />} />
          <Route exact path='/personal-information' element={<PersonalInformation />} />
          
          <Route exact path='/health-details' element={<HealthDetails />} />
          <Route exact path='/paternal-bibliography' element={<PaternalBibliography />} />
          <Route exact path='/maternal-bibliography' element={<MaternalBibliography />} />
          <Route exact path='/update-profile-image' element={<UpdateProfileImage />} />
          <Route exact path='/change-password' element={<ChangePassword />} />
          <Route exact path='/matching-profile' element={<MatchingProfile />} />

          




          

          

          
          

        </Routes>
   </BrowserRouter>
    </>
  );
}

export default App;
