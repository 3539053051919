import React  from 'react';

import LoginContent from "../content/LoginContent";

import Navbar from "../inc/Navbar";
import Footer from "../inc/Footer";

function Login() {
    return(
        <>
        <Navbar />
        <LoginContent/>
        <Footer />        
        </>
    );
}

export default Login;