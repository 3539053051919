import React  from 'react';

import IndexContent from "../content/IndexContent";

import Navbar from "../inc/Navbar";
import Banner from "../inc/Banner";
import Footer from "../inc/Footer";

function Home() {
    return(
        <>
        <Navbar />
        <Banner />
        <IndexContent/>
        <Footer />        
        </>
    );
}

export default Home;