// import React, { useState } from 'react';
// import {Link} from 'react-router-dom';

import React, {useState, useEffect} from "react";

import axios from "axios";
import {Link} from 'react-router-dom';

import {useNavigate } from 'react-router-dom';

axios.defaults.baseURL='https://suvivah.org/suvivah/api/';


function Navbar2 () {

  let navigate = useNavigate();

  // const [catlisting, setCatlisting] = useState([]);

  // async function getparentcat() {
  //     var resp=await axios.get("/getparentcat");
  //     var data=resp.data;
  //     setCatlisting(data);
  
  //  }

   
  useEffect(() => {  

    // getparentcat();
    
  }, [])


    return(
        <>        
   
    <div className="bg_top"></div>

    <div className="py-3 text-center logo">
        <Link to="/"><img src="images/logo.png" alt="" className="img-fluid" /></Link>
    </div>

<nav className="navbar navbar-expand-lg navbar-light ">
  <div className="container">    
    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <i class="fa fa-bars" aria-hidden="true"></i>
    </button>
    <div className="collapse navbar-collapse" id="navbarSupportedContent">
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <li className="nav-item">
          <Link className="nav-link active" aria-current="page" to="/">Home</Link>
        </li>
        {/* <li className="nav-item">
          <Link className="nav-link" to="/about">About Us</Link>
        </li>   */}

        <li className="nav-item dropdown">
          <Link className="nav-link dropdown-toggle" to="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          About Us
          </Link>
          <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><Link className="dropdown-item" to="/vision">Vision </Link></li>
            <li><Link className="dropdown-item" to="/mission">Mission</Link></li>
            {/* <li><Link className="dropdown-item" to="#">Action</Link></li>
            <li><Link className="dropdown-item" to="#">Another action</Link></li> */}
           
          </ul>
        </li> 



         <li className="nav-item">
          <Link className="nav-link" to="/success-story">Success Story</Link>
        </li>
         <li className="nav-item">
          <Link className="nav-link" to="/faqs">FAQs</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/contact-us">Contact Us</Link>
        </li>

                                   

     {/* <li className="nav-item dropdown">
          <Link className="nav-link dropdown-toggle" to="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Dropdown
          </Link>
          <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><Link className="dropdown-item" to="#">Action</Link></li>
            <li><Link className="dropdown-item" to="#">Another action</Link></li>
            <li><hr className="dropdown-divider"></li>
            <li><Link className="dropdown-item" to="#">Something else here</Link></li>
          </ul>
        </li>  */}
      
      </ul>


      <div className="d-flex">
       
        {!localStorage.getItem("pid")?
        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <li className="nav-item">
          <Link className="nav-link" aria-current="page" to="/login">
            <i className="fa fa-user-o me-2" aria-hidden="true"></i> Login</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link btn-register" to="/register"><span> Register</span></Link>
        </li>
        </ul>
        :
        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
             <li className="nav-item">
          <Link className="nav-link" aria-current="page" to="/dashboard">
            <i className="fa fa-tachometer me-2" aria-hidden="true"></i> Dashboard</Link>
        </li>
        <li className="nav-item">
          <button className="btn nav-link" aria-current="page" onClick={()=>{
            // alert(localStorage.getItem("pid")+', '+localStorage.getItem("profile_id")+', '+localStorage.getItem("userregstep"));
            localStorage.removeItem("pid");
            localStorage.removeItem("profile_id");
            localStorage.removeItem("userregstep");
            let path=`../login`;
            navigate(path);
          }}>
            <i class="fa fa-sign-out" aria-hidden="true"></i>  Logout</button>
        </li>
        
        </ul>
      }

           
      </div>
    </div>
  </div>
</nav>



   
        </>
    );
}

export default Navbar2;