
import React, {useState, useEffect} from "react";

import {Link, useNavigate } from 'react-router-dom';
import DashboardLeftNav from "../inc/DashboardLeftNav";

// import {useNavigate } from 'react-router-dom';

import axios from "axios";
axios.defaults.baseURL='https://suvivah.org/suvivah/api/';



function UpdateProfileImageContent() {

    let navigate = useNavigate();

    let [file,setFile]=useState(null);
    let [fileerr,setFileerr]=useState(false);
    let [ismsg,setIsmsg]=useState(false);

    let [btn,setBtn]=useState(true);

    async function updateprofileimage() {
        var flag=1;

        setIsmsg(false);
        if (file==null) {
            setFileerr(true);
            flag=0;
        }

        if (flag==1) {
            
            var fd=new FormData();
            fd.append("pid",localStorage.getItem("pid"));
            fd.append("file",file);
            var resp=await axios.post("https://suvivah.org/suvivah/api/updateprofileimage",fd);
            var data=resp.data;
            setIsmsg(true);
            setBtn(true);

            let path=`/update-profile-image`;
            navigate(path);

        }
    }

    return(
        <>

<div className="devider"></div>


<section className="register-row">
<div className="container">
    <div className="row">
        <div className="col-md-3">
            <DashboardLeftNav />
        </div>
        <div className="col-md-9">
            <h2 className="pb-3">Dashboard</h2>
            

        <div class="register_box">
            <div class="row mb-3">
                <div className="col-md-12">
                    {ismsg?<div className="text text-success text-center">Profile Image Updated!!</div>:''}
                    <h4>Update Profile Image</h4>
                </div>
                <div class="col-md-6">
                    <input type="file" className="form-control-file" accept=".jpg" onChange={(ev)=>{
                        setFile(ev.target.files[0]);
                        if (ev.target.files[0]) {
                            setBtn(false);
                        } else {
                            setBtn(true);
                        }
                    }} />
                    {fileerr?<div className="text text-danger small">profile image required</div>:''}
                </div>
            
            </div>
            <div class="row">
                <div class="col-md-12 text-center"><button type="button" class="btn btn-red px-5" 
                    onClick={updateprofileimage} disabled={btn}>Update Profile Image</button></div>
            </div>            
        </div>

                    {/* <div class="form-group">
                      <label>Update Profile Image</label>
                      <input type="file" className="file-upload-default" style={{visibility: 'hidden', position: 'absolute',}}/>
                      <div class="input-group col-xs-12">
                        <input type="text" class="form-control file-upload-info" disabled placeholder="Upload Image"/>
                        <span class="input-group-append">
                          <button class="file-upload-browse btn btn-primary" type="button">Upload</button>
                        </span>
                      </div>
                    </div> */}

        </div>
    </div>
</div>
</section>


        </>
    );
}

export default UpdateProfileImageContent;