
import React, {useState, useEffect} from "react";

import axios from "axios";
axios.defaults.baseURL='https://suvivah.org/suvivah/api/';



function SuccessStoryContent() {
    return(
        <>

<div class="devider"></div>
<section class="register-row">
    <div class="container">
        <div class="row">
            <div class="col-md-12">
                <header>
                    <h2 class="mb-4">Success Story</h2>                    
                </header>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
               {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras sollicitudin tincidunt nibh at laoreet. Vivamus sit amet risus vitae odio viverra efficitur sed at neque. Suspendisse quam ante, mattis vel sem sed, vestibulum cursus mi. Cras consequat laoreet felis, at rutrum massa. Etiam eget interdum risus. Vestibulum id nisi gravida, sollicitudin mauris a, mollis nulla. Suspendisse potenti. Phasellus commodo, est at mattis sodales, neque nulla accumsan nulla, et viverra quam lorem vitae urna. Etiam finibus lorem sit amet dolor tempus, vitae dictum risus vehicula. Pellentesque tempor sapien vestibulum, commodo sem a, posuere risus. Maecenas finibus maximus porta. Ut et posuere ligula. Fusce bibendum dapibus eros sit amet molestie. </p> */}
               <div className="testimonialBox text-center" style={{fontSize: 'x-large'}}>Coming Soon</div>
            </div>
        </div>


    </div>
</section>


        </>
    );
}

export default SuccessStoryContent;