import React  from 'react';

import PersonalInformationContent from "../content/PersonalInformationContent";

import Navbar2 from "../inc/Navbar2";
import Footer from "../inc/Footer";

function PersonalInformation() {
    return(
        <>
        <Navbar2 />
        <PersonalInformationContent/>
        <Footer />        
        </>
    );
}

export default PersonalInformation;