import React, {useState, useEffect} from "react";
import {Link, useNavigate } from 'react-router-dom';
import axios from "axios";
import DashboardLeftNav from "../inc/DashboardLeftNav";



function PaternalBibliographyContent() {
    let navigate = useNavigate();

    const [progress,setProgress]=useState(0);
    const [loader,setLoader]=useState(false);

    let [paternalinformation,setPaternalinformation]=useState('');

    let [castarr,setCastarr]=useState([]);
    let [tribearr,setTribearr]=useState([]);
    let [clanarr,SetClanarr]=useState([]);
    

    let [healthdetailsarr,setHealthdetailsarr]=useState("");

    let [maleoccupation,setMaleoccupation]=useState([]);

    async function showpaternalbibliography() {
        // setProgress(100);
        // setLoader(true);

        var resp2=await axios.get("https://suvivah.org/suvivah/api/reg_cast");
        var data2=resp2.data;
        setCastarr(data2);
      
        var fd=new FormData();
        fd.append("pid",localStorage.getItem("pid"));
        var resp=await axios.post("https://suvivah.org/suvivah/api/paternalinformation",fd);
        var data=resp.data;
        console.log(data);
        setPaternalinformation(data.row);

        setPaternalgrandfathername(data.row.paternal_grandfather_name);
        setGreatpaternalgrandfathername(data.row.great_paternal_grandfather_name);
        setPaternalgrandfatheroccupation(data.row.paternal_grandfather_occupation);
        setGreatpaternalgrandfatheroccupation(data.row.great_paternal_grandfather_occupation);
        setFcastid(data.row.father_cast);
        setTribearr(data.tribe);
        SetClanarr(data.clan);
        setMaleoccupation(data.maleoccupation);

        setPgcastid(data.row.paternal_grandfather_cast);

        setPgflifeexpectancy(data.health.paternal_grandfather_life_expectancy);
        setGpgflifeexpectancy(data.health.great_paternal_grandfather_life_expectancy);

        setHealthdetailsarr(data.health);  

        setPgfcdisease(data.health.paternal_grandfather_chronic_disease);
        setPgfcdiseasedetails(data.health.paternal_grandfather_chronic_disease_details);

        if (data.health.paternal_grandfather_chronic_disease=='No') {
            setPgfcdiseaseyes(false);
            setPgfcdiseaseno(true);

            setFcd(true);
        } else {
            setPgfcdiseaseyes(true);
            setPgfcdiseaseno(false);

            setFcd(false);
        }
        


        setGpgfcdisease(data.health.great_paternal_grandfather_chronic_disease);
        setGpgfcdiseasedetails(data.health.great_paternal_grandfather_chronic_disease_details);

        if (data.health.great_paternal_grandfather_chronic_disease=='No') {
            setGpgfcdiseaseyes(false);
            setGpgfcdiseaseno(true);

            setGpgfcd(true);
        } else {
            setGpgfcdiseaseyes(true);
            setGpgfcdiseaseno(false);

            setGpgfcd(false);
        }


      
        

        setGpgcastid(data.row.great_paternal_grandfather_cast);
        setFtribeid(data.row.father_tribe);

        setPgftribeid(data.row.paternal_grandfather_tribe);
        setGpgftribeid(data.row.great_paternal_grandfather_tribe);
        setFclan(data.row.father_clan);
        setPgfclan(data.row.paternal_grandfather_clan);
        setGpgfclan(data.row.great_paternal_grandfather_clan);
        
        
    }

    //UPDATE DATA
    let [paternalgrandfathername,setPaternalgrandfathername]=useState("");
    let [greatpaternalgrandfathername,setGreatpaternalgrandfathername]=useState("");
    let [paternalgrandfatheroccupation,setPaternalgrandfatheroccupation]=useState("");
    let [greatpaternalgrandfatheroccupation,setGreatpaternalgrandfatheroccupation]=useState("");

    let [fcastid,setFcastid]=useState("")
    let [ftribeid,setFtribeid]=useState("");
    let [fclan,setFclan]=useState("");

    let [pgcastid,setPgcastid]=useState("");
    let [gpgcastid,setGpgcastid]=useState("");
    
    let [pgftribeid,setPgftribeid]=useState("");
    let [gpgftribeid,setGpgftribeid]=useState("");
    
    let [pgfclan,setPgfclan]=useState("");
    let [gpgfclan,setGpgfclan]=useState("");

    let [pgflifeexpectancy,setPgflifeexpectancy]=useState("");
    let [gpgflifeexpectancy,setGpgflifeexpectancy]=useState("");

    let[pgfcdisease,setPgfcdisease]=useState("");
    let[pgfcdiseasedetails,setPgfcdiseasedetails]=useState("");

    let [pgfcdiseaseyes,setPgfcdiseaseyes]=useState(false);
    let [pgfcdiseaseno,setPgfcdiseaseno]=useState(false);

    let [fcd,setFcd]=useState(false);

    let[gpgfcdisease,setGpgfcdisease]=useState("");
    let[gpgfcdiseasedetails,setGpgfcdiseasedetails]=useState("");

    let [gpgfcdiseaseyes,setGpgfcdiseaseyes]=useState(false);
    let [gpgfcdiseaseno,setGpgfcdiseaseno]=useState(false);

     let [gpgfcd,setGpgfcd]=useState(false);
    
    let [ismsg,setIsmsg]=useState(false);

    async function updatepaternaldata() {
        setIsmsg(false);
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });

        var fd=new FormData();
        fd.append("pid",localStorage.getItem("pid"));
        fd.append("paternalgrandfathername",paternalgrandfathername);
        fd.append("greatpaternalgrandfathername",greatpaternalgrandfathername);
        fd.append("paternalgrandfatheroccupation",paternalgrandfatheroccupation);
        fd.append("greatpaternalgrandfatheroccupation",greatpaternalgrandfatheroccupation);
        fd.append("fcastid",fcastid);
        fd.append("ftribeid",ftribeid);
        fd.append("fclanid",fclan);
        fd.append("pgcastid",pgcastid);
        fd.append("gpgcastid",gpgcastid);
        fd.append("pgftribeid",pgftribeid);
        fd.append("gpgftribeid",gpgftribeid);
        fd.append("pgfclanid",pgfclan);
        fd.append("gpgfclanid",gpgfclan);
        fd.append("pgflifeexpectancy",pgflifeexpectancy);
        fd.append("gpgflifeexpectancy",gpgflifeexpectancy);
        fd.append("pgfcdisease",pgfcdisease);
        fd.append("pgfcdiseasedetails",pgfcdiseasedetails);
        fd.append("gpgfcdisease",gpgfcdisease);
        fd.append("gpgfcdiseasedetails",gpgfcdiseasedetails);
        var resp=await axios.post("https://suvivah.org/suvivah/api/updatepaternaldata",fd);
        var data=resp.data;

        setIsmsg(true);
    }


    useEffect(()=>{
        if (!localStorage.getItem("pid")) {
            let path=`/login`;
            navigate(path);
        }
        showpaternalbibliography();
    },[])


    



    return(
        <>

<div className="devider"></div>


<section className="register-row">
<div className="container">
    <div className="row">
        <div className="col-md-3">      

           <DashboardLeftNav />

        </div>
        <div className="col-md-9">
            <h2 className="pb-3">Dashboard</h2>
            

        <div class="register_box">
            <div class="row mb-3">
                <div className="col-md-12">
                    {ismsg?<div className="text text-success text-center">Paternal Details Updated!!</div>:''}
                    <h4>Paternal Bibliography</h4>
                </div>
             </div>

             <div class="row mb-3">   
                <div class="col-md-6">
                <label className="display-block w-100">Paternal Grandfather Name:</label>
                <input type="text" class="form-control small" placeholder="Paternal Grandfather Name" value={paternalgrandfathername} onChange={(ev)=>{
                    setPaternalgrandfathername(ev.target.value);
                }} /></div>
                <div class="col-md-6">
                <label className="display-block w-100">Great Paternal Grandfather Name:</label>
                <input type="text" class="form-control"  placeholder="Great Paternal Grandfather Name" value={greatpaternalgrandfathername} onChange={(ev)=>{
                    setGreatpaternalgrandfathername(ev.target.value);
                }} /></div>
            </div>

            <div class="row mb-3">   
                <div class="col-md-6">
                <label className="display-block w-100">Paternal Grandfather Occupation:</label>
                <select className="form-select" onChange={(ev)=>{
                    setPaternalgrandfatheroccupation(ev.target.value);
                    // if (ev.target.value.length>0) {
                    //     setFoccuerr(false);
                    // }
                }} value={paternalgrandfatheroccupation} >
                <option value={""}>--Select Paternal Grandfather Occupation--</option> 
                {maleoccupation.map((e)=>
                <option key={e.occupation_id} value={e.occupation_id}>{e.occupation_name}</option>
                )}
                </select>
                {/* <input type="text" class="form-control" placeholder="Paternal Grandfather Occupation" value={paternalgrandfatheroccupation} onChange={(ev)=>{
                    setPaternalgrandfatheroccupation(ev.target.value);
                }} /> */}
                
                </div>
                <div class="col-md-6">
                <label className="display-block w-100">Great Paternal Grandfather Occupation:</label>
                <select className="form-select" onChange={(ev)=>{
                    setGreatpaternalgrandfatheroccupation(ev.target.value);
                    // if (ev.target.value.length>0) {
                    //     setFoccuerr(false);
                    // }
                }} value={greatpaternalgrandfatheroccupation} >
                <option value={""}>--Select Great Paternal Grandfather Occupation--</option> 
                {maleoccupation.map((e)=>
                <option key={e.occupation_id} value={e.occupation_id}>{e.occupation_name}</option>
                )}
                </select>
                {/* <input type="text" class="form-control" placeholder="Great Paternal Grandfather Occupation" value={greatpaternalgrandfatheroccupation} onChange={(ev)=>{
                    setGreatpaternalgrandfatheroccupation(ev.target.value);
                }} /> */}
                
                </div>
            </div>





            {/* Paternal Grandfather Chronic Disease */}

            <div class="row mb-3">
                <div class="col-md-4">
                <label className="display-block w-100">Father Cast</label>
                    <select class="form-select" value={fcastid} onChange={(ev)=>{
                            setFcastid(ev.target.value);
                        }}  >
                        <option value={""}>--Select Cast--</option>
                        {castarr.map((e)=>
                            // <optgroup label={e.pcast_name} key={e.pcast_id}>
                            
                                <option key={e.cast_id} value={e.cast_id}>{e.cast_name}</option>
                            
                            // </optgroup>
                        )}
                    </select>
                </div>
                <div class="col-md-4">
                <label className="display-block w-100">Father Tribe</label>
                    <select class="form-select" value={ftribeid} onChange={(ev)=>{
                            setFtribeid(ev.target.value);
                        }}  >
                        <option value={""}>--Select any one--</option>
                        {tribearr.map((t)=>
                            <option value={t.tribe_id} key={t.tribe_id}>{t.tribe_name}</option>
                            )}
                    </select>
                </div>
                <div class="col-md-4">
                <label className="display-block w-100">Father Clan</label>
                    {/* <select class="form-select" value={fclan} onChange={(ev)=>{
                            setFclan(ev.target.value);
                        }} >
                        <option value={""}>--Select any one--</option>
                        {clanarr.map((t)=>
                            <option value={t.clan_id} key={t.clan_id}>{t.clan_name}</option>
                            )}
                    </select> */}
                    <input type="text" class="form-control" placeholder="Clan Name" onChange={(ev)=>{
                        setFclan(ev.target.value);
                    }} value={fclan} />
                </div>
            </div>

            

            <div class="row mb-3">
                <div class="col-md-6">
                    <label className="display-block w-100">Paternal Grandfather Caste</label>
                    <select class="form-select" onChange={(ev)=>{
                            setPgcastid(ev.target.value);
                        }} value={pgcastid} >
                        <option value={""}>--Select Cast--</option>
                        {castarr.map((e)=>
                            // <optgroup label={e.pcast_name} key={e.pcast_id}>
                           
                                <option key={e.cast_id} value={e.cast_id}>{e.cast_name}</option>
                           
                            // </optgroup>
                        )}
                    </select>
                </div>
                <div class="col-md-6">
                    <label className="display-block w-100">Greate Paternal Grandfather Caste</label>
                    <select class="form-select" onChange={(ev)=>{
                            setGpgcastid(ev.target.value);
                        }} value={gpgcastid} >
                        <option value={""}>--Select Cast--</option>
                        {castarr.map((e)=>
                            // <optgroup label={e.pcast_name} key={e.pcast_id}>
                            
                                <option key={e.cast_id} value={e.cast_id}>{e.cast_name}</option>
                           
                            // </optgroup>
                        )}
                    </select>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-md-6">
                <label className="display-block w-100">Paternal Grandfather Tribe</label>
                    <select class="form-select" value={pgftribeid} onChange={(ev)=>{
                            setPgftribeid(ev.target.value);
                        }}>
                        <option value={""}>--Select any one--</option>
                        {tribearr.map((t)=>
                            <option value={t.tribe_id} key={t.tribe_id}>{t.tribe_name}</option>
                        )}
                    </select>
                </div>
                <div class="col-md-6">
                <label className="display-block w-100">Greate Paternal Grandfather Tribe</label>
                    <select class="form-select" value={gpgftribeid} onChange={(ev)=>{
                            setGpgftribeid(ev.target.value);
                        }} >
                        <option value={""}>--Select any one--</option>
                        {tribearr.map((t)=>
                            <option value={t.tribe_id} key={t.tribe_id}>{t.tribe_name}</option>
                            )}
                    </select>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-md-6">
                <label className="display-block w-100">Paternal Grandfather Clan</label>
                    {/* <select class="form-select" value={pgfclan} onChange={(ev)=>{
                            setPgfclan(ev.target.value);
                        }}>
                        <option value={""}>--Select any one--</option>
                        {clanarr.map((t)=>
                            <option value={t.clan_id} key={t.clan_id}>{t.clan_name}</option>
                            )}
                    </select> */}
                    <input type="text" class="form-control" placeholder="Grandfather's Clan Name" onChange={(ev)=>{
                        setPgfclan(ev.target.value);
                    }} value={pgfclan} />
                </div>
                <div class="col-md-6">
                    <label className="display-block w-100">Greate Paternal Grandfather Clan</label>
                    {/* <select class="form-select" value={gpgfclan} onChange={(ev)=>{
                            setGpgfclan(ev.target.value);
                        }} >
                        <option value={""}>--Select any one--</option>
                        {clanarr.map((t)=>
                            <option value={t.clan_id} key={t.clan_id}>{t.clan_name}</option>
                            )}
                    </select> */}
                    <input type="text" class="form-control" placeholder="Great Grandfather's Clan Name" onChange={(ev)=>{
                        setGpgfclan(ev.target.value);
                    }} value={gpgfclan} />
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-md-6">
                    <label className="display-block w-100">Paternal Grandfather Life Expectancy</label>
                    <select class="form-select" onChange={(ev)=>{
                            setPgflifeexpectancy(ev.target.value);
                        }} value={pgflifeexpectancy}>
                        <option value={""}>--Select any one--</option>
                        <option value={"Still Alive"}>Still Alive</option>
                        {[...Array(133)].map((x, i) =>
                           
                           <option value={i+18} key={i}>{18+ i} Years</option>
                       )}
                    </select>
                </div>
                <div class="col-md-6">
                <label className="display-block w-100">Greate Paternal Grandfather Life Expectancy</label>
                {/* <div class="row">
                    <div class="col-md-6"> */}
                    <select class="form-select" onChange={(ev)=>{
                            setGpgflifeexpectancy(ev.target.value);
                        }} value={gpgflifeexpectancy}>
                        <option value={""}>--Select any one--</option>
                        <option value={"Still Alive"}>Still Alive</option>
                        {[...Array(133)].map((x, i) =>                           
                           <option value={i+18} key={i}>{18+ i} Years</option>
                       )}
                    </select>
                {/* </div>
                    </div> */}
                </div>
                
            </div>

            <div class="row mb-3">
                <div class="col-md-12">                    
                <label class="display-block pb-3 me-3">Paternal Grandfather Chronic Disease</label>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="fatherschronic" id="fatherschronic1" value="Yes" checked={pgfcdiseaseyes} onChange={(ev)=>{
                        setPgfcdisease(ev.target.value);
                        setPgfcdiseaseyes(true);
                        setPgfcdiseaseno(false);
                        setFcd(false);
                        setPgfcdiseasedetails(healthdetailsarr.paternal_grandfather_chronic_disease_details);
                       
                    }} />
                    <label class="form-check-label" for="fatherschronic1">Yes</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="fatherschronic" id="fatherschronic2" value="No" checked={pgfcdiseaseno} onChange={(ev)=>{
                        setPgfcdisease(ev.target.value);
                        setPgfcdiseaseyes(false);
                        setPgfcdiseaseno(true);
                        setFcd(true);
                        setPgfcdiseasedetails("");
                    }}  />
                    <label class="form-check-label" for="fatherschronic2">No</label>
                </div>
                </div>
                <div class="col-md-12">
                    <textarea class="form-control" rows="1" id="comment" name="text" placeholder="Paternal Grandfather Chronic Disease Details" value={pgfcdiseasedetails} onChange={(ev)=>{
                    setPgfcdiseasedetails(ev.target.value);
                    
                }} disabled={fcd}></textarea>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-md-12">                    
                <label class="display-block pb-3 me-3">Greate Paternal Grandfather Chronic Disease</label>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="aa" id="fatherschronic3" value="Yes" checked={gpgfcdiseaseyes} onChange={(ev)=>{
                        setGpgfcdisease(ev.target.value);
                        setGpgfcdiseaseyes(true);
                        setGpgfcdiseaseno(false);
                        setGpgfcd(false);
                        setGpgfcdiseasedetails(healthdetailsarr.great_paternal_grandfather_chronic_disease_details);
                       
                    }} />
                    <label class="form-check-label" for="fatherschronic">Yes</label>
                </div>
                <div class="form-check form-check-inline">
                    <input class="form-check-input" type="radio" name="aa" id="fatherschronic4" value="No" checked={gpgfcdiseaseno} onChange={(ev)=>{
                        setGpgfcdisease(ev.target.value);
                        setGpgfcdiseaseyes(false);
                        setGpgfcdiseaseno(true);
                        setGpgfcd(true);
                        setGpgfcdiseasedetails("");
                    }}  />
                    <label class="form-check-label" for="fatherschronic4">No</label>
                </div>
                </div>
                <div class="col-md-12">
                    <textarea class="form-control" rows="1" id="comment" name="text" placeholder="Greate Paternal Grandfather Chronic Disease Details" value={gpgfcdiseasedetails} onChange={(ev)=>{
                    setGpgfcdiseasedetails(ev.target.value);
                    
                }} disabled={gpgfcd}></textarea>
                </div>
            </div>



            <div class="row">
                <div class="col-md-12 text-center"><button type="button" class="btn btn-red px-5" onClick={updatepaternaldata}>Update</button></div>
            </div>

            
        </div>

        </div>
    </div>
</div>
</section>


        </>
    );
}

export default PaternalBibliographyContent;