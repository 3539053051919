import React  from 'react';

import RegisterContent2 from "../content/RegisterContent2";

import Navbar from "../inc/Navbar";
import Footer from "../inc/Footer";

function Register2() {
    return(
        <>
        <Navbar />
        <RegisterContent2/>
        <Footer />        
        </>
    );
}

export default Register2;