
import React, {useState, useEffect} from "react";
import {Link, useNavigate } from 'react-router-dom';

import axios from "axios";

import DashboardLeftNav from "../inc/DashboardLeftNav";

axios.defaults.baseURL='https://suvivah.org/suvivah/api/';



function CommunicationDetailsContent() {

    
    const [progress,setProgress]=useState(0);
    const [loader,setLoader]=useState(false);

    const [countrystatus,setCountrystatus]=useState(true);

    let [communicationinfo,setCommunicationinfoarr]=useState('');
    let [cn, setCn]=useState([]);
    let [st,setSt]=useState([]);

    let [cnameid,setCnameid]=useState("");



    let navigate = useNavigate();

    async function showcommunicationinfo() {


        setProgress(100);
        setLoader(true);

      
        var fd=new FormData();
        fd.append("pid",localStorage.getItem("pid"));
        var resp=await axios.post("showcommunication",fd);
        var data=resp.data;
        console.log(data);
        setCommunicationinfoarr(data.row);
        setCn(data.country);
        setSt(data.state);
        setCnameid(data.row.countryid);
        // console.log(data.row.countryid);

        setAddr1(data.row.addr1);
        setAddr2(data.row.addr2);
        setCity(data.row.city);
        setDist(data.row.district);
        setPin(data.row.pincode);
        setState(data.row.state);
        // setCnameid(data.row.country);

        // getstatelist();

        if (data.row.countryid!=null) {
            setCountrystatus(true);
        } else {
            setCountrystatus(false);
        }
        

    }

    let [addr1,setAddr1]=useState("");
    let [addr2,setAddr2]=useState("");
    let [city,setCity]=useState("");
    let [dist,setDist]=useState("");
    let [pin,setPin]=useState("");
    let [state,setState]=useState("");
    // let [country,setCountry]=useState("");

    let [addr1err,setAddr1err]=useState(false);
    let [cityerr,setCityerr]=useState(false);
    let [disterr,setDisterr]=useState(false);
    let [pinerr,setPinerr]=useState(false);
    let [stateerr,setStateerr]=useState(false);
    let [countryerr,setCountryerr]=useState(false);
    
    // let [msg,setMsg]=useState("");
    let [ismsg,setIsmsg]=useState(false);

    async function updatecommunication() {
        var flag=1;

        alert(addr1);
        setIsmsg(false);
        if (addr1.length==0) {
            setAddr1err(true);
            flag=0;
            alert("addr1 error");
        }
        if (city.length==0) {
            setCityerr(true);
            flag=0;
            alert("city error");
        }
        if (pin.length<6) {
            setPinerr(true);
            flag=0;
            alert("pin error");
        }
        // if (dist.length==0 || dist.trim()=="") {
        //     setDisterr(true);
        //     flag=0;
        // }
        if (state.length==0) {
            setStateerr(true);
            flag=0;
        }
        if (cnameid.length==0) {
            setCountryerr(true);
            flag=0;
        }

        // alert(name+', '+email+', '+gender+', '+phone)

        if (flag==1) {
            var fd=new FormData();
            fd.append("pid",localStorage.getItem("pid"));
            fd.append("addr1",addr1);
            fd.append("addr2",addr2);
            fd.append("city",city);
            fd.append("pin",pin);
            fd.append("dist",dist);
            fd.append("state",state);
            fd.append("country",cnameid);

            var resp=await axios.post("https://suvivah.org/suvivah/api/updatecommunication",fd);
            var data=resp.data;

            setIsmsg(true);

            setCountrystatus(true);
        }

        

    }

    async function showstates(ev) {
        var fd=new FormData();
        fd.append("countryid",ev);

        var resp=await axios.post("https://suvivah.org/suvivah/api/showcuntrywisestatelist",fd);
        var data=resp.data;

        console.log(data.state);

        setSt(data.state);
    }

    

    // async function getstatelist() {
        
    // }

    useEffect(()=>{
        if (!localStorage.getItem("pid")) {
            let path=`/login`;
            navigate(path);
        }
        showcommunicationinfo();
        // getstatelist();
    },[])




    return(
        <>

<div className="devider"></div>


<section className="register-row">
<div className="container">
    <div className="row">
        <div className="col-md-3">

        

        <DashboardLeftNav />          



        </div>
        <div className="col-md-9">
            <h2 className="pb-3">Dashboard</h2>
            

        <div class="register_box">
            <div class="row mb-3">
                <div className="col-md-12">
                    {ismsg?<div className="text text-success text-center">Communcation Updated!!</div>:''}
                    <h4>Communication Details</h4>
                </div>                
            </div>
            <div class="row mb-3">               
                <div class="col-md-6">
                     <textarea class="form-control" rows="2" value={addr1} onChange={(ev)=>{
                        setAddr1(ev.target.value);
                        if (ev.target.value.length>0 && ev.target.value.trim()!="") {
                            setAddr1err(false);
                        }
                     }} />
                    {addr1err?<div className="text text-danger text-small">Address Line Required</div>:''}
                </div>
                <div class="col-md-6">
                    <textarea class="form-control" rows="2" value={addr2} onChange={(ev)=>{
                        setAddr2(ev.target.value);

                     }} />
                   
                </div>
                
            </div>

            <div class="row mb-3">          
                <div class="col-md-6">
                     <input type="text" class="form-control" value={city} placeholder="City" onChange={(ev)=>{
                        setCity(ev.target.value);
                        if (ev.target.value.length>0 && ev.target.value.trim()!="") {
                            setCityerr(false);
                        }
                     }} />
                     {cityerr?<div className="text text-danger text-small">City Required</div>:''}
                </div>
                <div class="col-md-6">
                    <input type="text" class="form-control"  placeholder="District (Otional)" value={dist} onChange={(ev)=>{
                        setDist(ev.target.value);
                        if (ev.target.value.length>0 && ev.target.value.trim()!="") {
                            setDisterr(false);
                        }
                     }} />  
                     {disterr?<div className="text text-danger text-small">District Required</div>:''}                  
                </div>
            </div>

             <div class="row mb-3">          
                <div class="col-md-6">
                     <input type="text" class="form-control" placeholder="Pincode" value={pin} onChange={(ev)=>{
                        setPin(ev.target.value);
                        if (ev.target.value.length==6 && ev.target.value.trim()!="") {
                            setPinerr(false);
                        }
                     }} />
                     {pinerr?<div className="text text-danger text-small">Pin Code Required</div>:''}
                </div>
                <div class="col-md-6">
                    {/* <input type="text" class="form-control" placeholder="State" value={state} onChange={(ev)=>{
                        setState(ev.target.value);
                        if (ev.target.value.length>0 && ev.target.value.trim()!="") {
                            setStateerr(false);
                        }
                     }} /> */}
                     <select class="form-select" onChange={(ev)=>{
                            setState(ev.target.value);
                            if (ev.target.value.length>1) {
                                setStateerr(false);
                            }
                        }} value={state}>
                        <option value={""}>--Select State--</option>
                        {st.map((e)=>
                        // <option key={e.country_id} value={e.country_id}>{e.country_name}</option>
                        <option key={e.id} value={e.id}>{e.name}</option>
                        )}
                    </select>
                     {stateerr?<div className="text text-danger text-small">State Required</div>:''}
                </div>
            </div>

            <div class="row mb-4">          
                <div class="col-md-6">
                    <select class="form-select" value={cnameid} onChange={(ev)=>{
                        setCnameid(ev.target.value);
                        if (ev.target.value.length>0) {
                            setCountryerr(false);

                            showstates(ev.target.value);
                        }
                    }} disabled={countrystatus}>
                        <option value={""}>--Select Country--</option>    
                        {cn.map((c)=>
                            // <option value={c.country_id} key={c.country_id}>{c.country_name}</option>
                            <option value={c.id} key={c.id}>{c.name}</option>
                        )}                   
                                             
                    </select>        
                    {countryerr?<div className="text text-danger text-small">Select Country</div>:''}
                </div>
                
            </div>


            <div class="row">
                <div class="col-md-12 text-center"><button type="button" class="btn btn-red px-5" onClick={updatecommunication}>Update</button></div>
            </div>

            
        </div>

        </div>
    </div>
</div>
</section>


        </>
    );
}

export default CommunicationDetailsContent;