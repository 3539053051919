import React  from 'react';

import MissionVisionContent from "../content/MissionVisionContent";

import Navbar from "../inc/Navbar";
import Footer from "../inc/Footer";

function MissionVision() {
    return(
        <>
        <Navbar />
        <MissionVisionContent/>
        <Footer />        
        </>
    );
}

export default MissionVision;