import React  from 'react';

import MatchingProfileContent from "../content/MatchingProfileContent";

import Navbar2 from "../inc/Navbar2";
import Footer from "../inc/Footer";

function MatchingProfile() {
    return(
        <>
        <Navbar2 />
        <MatchingProfileContent/>
        <Footer />        
        </>
    );
}

export default MatchingProfile;