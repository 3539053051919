
import React, {useState, useEffect} from "react";
import {Link, useNavigate } from 'react-router-dom';
import axios from "axios";
import DashboardLeftNav from "../inc/DashboardLeftNav";
// For Modal
import Modal from 'react-modal';
// For Modal




function PersonalInformationContent() {
    let navigate = useNavigate();

    const [progress,setProgress]=useState(0);
    const [loader,setLoader]=useState(false);

    let [personalinfo,setPersonalinfoarr]=useState([]);
    let [castarr,setCastarr]=useState([]);
    let [tribearr,setTribearr]=useState([]);
    let [clanarr,setClanarr]=useState([]);
    let [rashiarr,setRashiarr]=useState([]);

    let [gonarr,setGonarr]=useState([]);
    let [lognoarr,setLognoarr]=useState([]);

    let [maleoccupation,setMaleoccupation]=useState([]);
    let [femaleoccupation,setFemaleoccupation]=useState([]);
    let [educationalqualification,setEducationalqualification]=useState([]);
    let [otherqualification,setOtherqualification]=useState([]);
    let [userprofession,setUserprofession]=useState([]);
    let [userskill,setUserskill]=useState([]);
    let [religionarr,setReligionarr]=useState([]);

    const [ismangalikyes,setIsmangalikyes]=useState("");
    const [ismangalikno,setIsmangalikno]=useState("");

    const [initiationyes,setInitiationyes]=useState("");
    const [initiationno,setInitiationno]=useState("");

    const [userdietyes,setUserdietyes]=useState("");
    const [userdietno,setUserdietno]=useState("");

    // For Modal
    const customStyles = {
        content: {
          top: '50%',
          // left: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          background: 'red',
          color: '#fff',
        },
    };

    const formgroup={
        margin: '10px',
        fontWeight: 700
    };

    const formgroupmodal={
        padding: '10px',
        fontWeight: 700,
        border: '0px solid #b9b4cf',
        borderRadius: '4px',
        marginBottom: '5px'
    };

    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }
    // FOr Modal

    async function showpersonalinformation() {
        // setProgress(100);
        setLoader(true);

        var resp2=await axios.get("https://suvivah.org/suvivah/api/reg_cast");
        var data2=resp2.data;
        setCastarr(data2);
        
        var fd=new FormData();
        fd.append("pid",localStorage.getItem("pid"));
        var resp=await axios.post("https://suvivah.org/suvivah/api/showpersonalinfo",fd);
        var data=resp.data;
        console.log(data);
        setPersonalinfoarr(data.row);

        setTribearr(data.tribe);
        setClanarr(data.clan);
        setRashiarr(data.rashi);
        setGonarr(data.gon);
        setLognoarr(data.logno);

        setMaleoccupation(data.maleoccupation);
        setFemaleoccupation(data.femaleoccupation);
        setEducationalqualification(data.educationalqualification);
        setOtherqualification(data.otherqualification);
        setUserprofession(data.userprofession);
        setUserskill(data.userskill);
        
        setReligionarr(data.religion);
        
        setFname(data.row.father_name);
        setMname(data.row.mother_name);
        setFoccu(data.row.father_occupation);
        setMoccu(data.row.mother_occupation);
        setQuali(data.row.educational_qualification);
        setOtherquali(data.row.other_qualification);
        setProfession(data.row.profession);
        setSkill(data.row.user_special_skill); 
        setSkincolor(data.row.user_skin_color);   

        setCastid(data.row.cast_id);
        setTribeid(data.row.tribe_id);
        setClanid(data.row.clan_id);
        setRashiid(data.row.rashi_id);
        setGonid(data.row.gon_id);
        setLognoid(data.row.logno_id);

        setHeight(data.row.height);
        setWeight(data.row.weight);

        setReligion(data.row.religion);
        setCommunity(data.row.community);

        setDob(data.row.dateofbirth);
        setPob(data.row.placeofbirth);
        setTob(data.row.timeofbirth);
        setMangalik(data.row.is_mangalik);
        setInitiation(data.row.user_initiation);
        setUserdiet(data.row.user_diet);

        // alert(data.row.is_mangalik);

        if (data.row.is_mangalik=="Yes") {
            // alert("OK");
            setIsmangalikyes(true);
            // setIsmno(false);
        } else {
            // alert("CANCEL");
            setIsmangalikno(true);
            // setIsmyes(false);
        }

        // initiation

        if (data.row.user_initiation=="Yes") {
            // alert("OK");
            setInitiationyes(true);
            // setIsmno(false);
        } else {
            // alert("CANCEL");
            setInitiationno(true);
            // setIsmyes(false);
        }

        //user diet

        if (data.row.user_diet=="Veg") {
            // alert("OK");
            setUserdietyes(true);
            // setIsmno(false);
        } else {
            // alert("CANCEL");
            setUserdietno(true);
            // setIsmyes(false);
        }
      
        
    }


    //UPDATE DATA
    let [fname,setFname]=useState("");
    let [fnameerr,setFnameerr]=useState(false);

    let [mname,setMname]=useState("");
    let [mnameerr,setMnameerr]=useState(false);

    let [foccu,setFoccu]=useState("");
    let [foccuerr,setFoccuerr]=useState(false);

    let [moccu,setMoccu]=useState("");
    let [moccuerr,setMoccuerr]=useState(false);
    
    let [quali,setQuali]=useState("");
    let [qualierr,setQualierr]=useState(false);

    let [otherquali,setOtherquali]=useState("");
    let [otherqualierr,setOtherqualierr]=useState(false);

    let [profession,setProfession]=useState("");
    let [professionerr,setProfessionerr]=useState(false);

    let [skill,setSkill]=useState("");
    let [skillerr,setSkillerr]=useState(false);
    
    let [skincolor,setSkincolor]=useState("");
    let [skincolorerr,setSkincolorerr]=useState(false);

    let [castid,setCastid]=useState("");
    let [castiderr,setCastiderr]=useState(false);

    let [tribeid,setTribeid]=useState("");
    let [tribeiderr,setTribeiderr]=useState(false);

    let [clanid,setClanid]=useState("");
    let [claniderr,setClaniderr]=useState(false);

    let [rashiid,setRashiid]=useState("");
    let [rashiiderr,setRashiiderr]=useState(false);

    let [lognoid,setLognoid]=useState("");
    let [lognoiderr,setLognoiderr]=useState(false);

    let [gonid,setGonid]=useState("");
    let [goniderr,setGoniderr]=useState(false);

    let [height,setHeight]=useState("");
    let [heighterr,setHeighterr]=useState(false);

    let [weight,setWeight]=useState("");
    let [weighterr,setWeighterr]=useState(false);

    let [community,setCommunity]=useState("");
    let [communityerr,setCommunityerr]=useState(false);

    let [religion,setReligion]=useState("");
    let [religionerr,setReligionerr]=useState(false);

    let [dob,setDob]=useState("");
    let [doberr,setDoberr]=useState(false);

    let [pob,setPob]=useState("");
    let [poberr,setPoberr]=useState(false);

    let [tob,setTob]=useState("");
    let [toberr,setToberr]=useState(false);
    
    let [mangalik,setMangalik]=useState("");
    let [mangalikerr,setMangalikerr]=useState(false);

    let [initiation,setInitiation]=useState("");
    let [initiationerr,setInitiationerr]=useState(false);

    let [userdiet,setUserdiet]=useState("");
    let [userdieterr,setUserdieterr]=useState(false);

    let [kusthifile,setkusthifile]=useState(null);

    let [ismsg,setIsmsg]=useState(false);

    async function updatePersonalInformation() {
        var flag=1;
        
        setIsmsg(false);
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        
        // if (fname.length==0 && fname.trim()=="") {
        //     setFnameerr(true);
        //     flag=0;
        // }
        // if (mname.length==0 && mname.trim()=="") {
        //     setMnameerr(true);
        //     flag=0;
        // }
        
        // if (foccu.length==0 && foccu.trim()=="") {
        //     setFoccuerr(true);
        //     flag=0;
        // }
        // if (moccu.length==0 && moccu.trim()=="") {
        //     setMoccuerr(true);
        //     flag=0;
        // }
        
        // if (quali.length==0 && quali.trim()=="") {
        //     setQualierr(true);
        //     flag=0;
        // }
        
        // if (otherquali.length==0 && otherquali.trim()=="") {
        //     setOtherqualierr(true);
        //     flag=0;
        // }
        // if (profession.length==0) {
        //     setProfessionerr(true);
        //     flag=0;
        // }

        // if (skill.length==0 && skill.trim()=="") {
        //     setSkillerr(true);
        //     flag=0;
        // }
        // if (skincolor.length==0 && skincolor.trim()=="") {
        //     setSkincolorerr(true);
        //     flag=0;
        // }
        
        // if (castid.length==0) {
        //     setCastiderr(true);
        //     flag=0;
        // }
        // if (tribeid.length==0) {
        //     setTribeiderr(true);
        //     flag=0;
        // }
        // if (clanid.length==0) {
        //     setClaniderr(true);
        //     flag=0;
        // }
        // if (rashiid.length==0) {
        //     setRashiiderr(true);
        //     flag=0;
        // }
        // if (lognoid.length==0) {
        //     setLognoiderr(true);
        //     flag=0;
        // }
        // if (gonid.length==0) {
        //     setGoniderr(true);
        //     flag=0;
        // }

        // if (height.length==0) {
        //     setHeighterr(true);
        //     flag=0;
        // }
        // if (weight.length==0) {
        //     setWeighterr(true);
        //     flag=0;
        // }
        // if (community.length==0) {
        //     setCommunityerr(true);
        //     flag=0;
        // }
        // if (religion.length==0) {
        //     setReligionerr(true);
        //     flag=0;
        // }
        
        // if (dob.length==0) {
        //     setDoberr(true);
        //     flag=0;
        // }
        // if (pob.length==0) {
        //     setPoberr(true);
        //     flag=0;
        // }
        // if (tob.length==0) {
        //     setToberr(true);
        //     flag=0;
        // }
        // if (mangalik.length==0) {
        //     setMangalikerr(true);
        //     flag=0;
        // }
        // if (initiation.length==0) {
        //     setInitiationerr(true);
        //     flag=0;
        // }
        // if (userdiet.length==0) {
        //     setUserdieterr(true);
        //     flag=0;
        // }
        
        if (flag==1) {
            // alert("ok");
            var fd=new FormData();
            fd.append("pid",localStorage.getItem("pid"));
            fd.append("fname",fname);
            fd.append("mname",mname);
            fd.append("foccu",foccu);
            fd.append("moccu",moccu);
            fd.append("quali",quali);
            fd.append("otherquali",otherquali);
            fd.append("profession",profession);
            fd.append("skill",skill);
            fd.append("skincolor",skincolor);
            fd.append("castid",castid);
            fd.append("tribeid",tribeid);
            fd.append("clanid",clanid);
            fd.append("rashiid",rashiid);
            fd.append("lognoid",lognoid);
            fd.append("gonid",gonid);
            fd.append("height",height);
            fd.append("weight",weight);
            fd.append("community",community);
            fd.append("religion",religion);
            fd.append("dob",dob);
            fd.append("pob",pob);
            fd.append("tob",tob);
            fd.append("mangalik",mangalik);
            fd.append("initiation",initiation);
            fd.append("userdiet",userdiet);
            fd.append("kusthifile",kusthifile);
            var resp=await axios.post("https://suvivah.org/suvivah/api/updatepersonalinformation",fd);
            var data=resp.data;

            setIsmsg(true);
        }
    }


    // https://suvivah.org/suvivah/api/reg_cast

    useEffect(()=>{
        if (!localStorage.getItem("pid")) {
            let path=`/login`;
            navigate(path);
        }
        showpersonalinformation();
    },[])

    return(
        <>

<div className="devider"></div>


<section className="register-row">
<div className="container">
    <div className="row">
        <div className="col-md-3">

       

        <DashboardLeftNav />
       
        </div>
        <div className="col-md-9">
            <h2 className="pb-3">Dashboard</h2>
            

        <div className="register_box">
            <div className="row mb-3">
                <div className="col-md-12">
                    {ismsg?<div className="text text-success text-center">Personal Information Updated!!</div>:''}
                    <h4>Personal Information</h4> 
                </div>                
            </div>

            <div className="row mb-3">          
                    <div className="col-md-6">
                        <label>Father's Name</label>
                        <input type="text" className="form-control" value={fname} placeholder="Father's Name" onChange={(ev)=>{
                            setFname(ev.target.value);
                            if (ev.target.value.length>0) {
                                setFnameerr(false);
                            }
                        }} />
                        {fnameerr?<div className="text text-danger small">Father's Name Required</div>:''}
                    </div>
                    <div className="col-md-6">
                        <label>Mother's Name</label>
                        <input type="text" className="form-control" value={mname} placeholder="Mother's Name" onChange={(ev)=>{
                            setMname(ev.target.value);
                            if (ev.target.value.length>0) {
                                setMnameerr(false);
                            }
                        }} />
                        {mnameerr?<div className="text text-danger small">Mother's Name Required</div>:''}
                    </div>
                </div>

                <div className="row mb-3">          
                    <div className="col-md-6">
                        <label>Father's Occupation</label>
                        <select className="form-select" onChange={(ev)=>{
                            setFoccu(ev.target.value);
                            if (ev.target.value.length>0) {
                                setFoccuerr(false);
                            }
                        }} value={foccu} >
                        <option value={""}>--Select Father's Occupation--</option> 
                        {maleoccupation.map((e)=>
                        <option key={e.occupation_id} value={e.occupation_id}>{e.occupation_name}</option>
                        )}
                        </select>
                         {/* <input type="text" className="form-control" value={foccu} placeholder="Father's Occupation" onChange={(ev)=>{
                            setFoccu(ev.target.value);
                            if (ev.target.value.length>0) {
                                setFoccuerr(false);
                            }
                        }} /> */}
                        {foccuerr?<div className="text text-danger small">Father's Occupation Required</div>:''}
                    </div>
                    <div className="col-md-6">
                        <label>Mother's Occupation</label>
                        <select className="form-select" onChange={(ev)=>{
                            setMoccu(ev.target.value);
                            if (ev.target.value.length>0) {
                                setMoccuerr(false);
                            }
                        }} value={moccu} >
                        <option value={""}>--Select Mother's Occupation--</option> 
                        {femaleoccupation.map((e)=>
                        <option key={e.occupation_id} value={e.occupation_id}>{e.occupation_name}</option>
                        )}
                        </select>
                        {/* <input type="text" className="form-control"  value={moccu} placeholder="Mother's Occupation" onChange={(ev)=>{
                            setMoccu(ev.target.value);
                            if (ev.target.value.length>0) {
                                setMoccuerr(false);
                            }
                        }} /> */}
                        {moccuerr?<div className="text text-danger small">Mother's Occupation Required</div>:''}
                    </div>
                </div>

                <div className="row mb-3">          
                    <div className="col-md-6">
                        <label>Educational Qualification</label>
                        <select className="form-select" onChange={(ev)=>{
                            setQuali(ev.target.value);
                            if (ev.target.value.length>0) {
                                setQualierr(false);
                            }
                        }} value={quali} >
                        <option value={""}>--Select Educational Qualification--</option> 
                        {educationalqualification.map((e)=>
                        <option key={e.educational_qualification_id} value={e.educational_qualification_id}>{e.educational_qualification_name}</option>
                        )}
                        </select>

                         {/* <input type="text" className="form-control" value={quali} placeholder="Educational Qualificationer" onChange={(ev)=>{
                            setQuali(ev.target.value);
                            if (ev.target.value.length>0) {
                                setQualierr(false);
                            }
                        }} /> */}
                        {qualierr?<div className="text text-danger small">Qualification Required</div>:''}
                    </div>
                    <div className="col-md-6">
                        <label>Other Qualification</label>
                        <select className="form-select" onChange={(ev)=>{
                            setOtherquali(ev.target.value);
                            if (ev.target.value.length>0) {
                                setOtherqualierr(false);
                            }
                        }} value={otherquali} >
                        <option value={""}>--Select Other Qualification--</option> 
                        {otherqualification.map((e)=>
                        <option key={e.other_qualification_id} value={e.other_qualification_id}>{e.other_qualification_name}</option>
                        )}
                        </select>
                        {/* <input type="text" className="form-control" value={otherquali} placeholder="Other Qualification" onChange={(ev)=>{
                            setOtherquali(ev.target.value);
                            if (ev.target.value.length>0) {
                                setOtherqualierr(false);
                            }
                        }} /> */}
                        {otherqualierr?<div className="text text-danger small">Other Qualification Required</div>:''}
                    </div>
                </div>

                <div className="row mb-3">

                    <div className="col-md-12">
                        <label>User Profession</label>
                        <select className="form-select" onChange={(ev)=>{
                            setProfession(ev.target.value);
                            if (ev.target.value.length>0) {
                                setProfessionerr(false);
                            }
                        }} value={profession} >
                        
                        <option value={""}>--Select Profession--</option>  
                        {userprofession.map((e)=>
                        <option key={e.profession_id} value={e.profession_id}>{e.profession_name}</option>
                        )}
                        </select>
                        {professionerr?<div className="text text-danger small">Profession Required</div>:''}

                    </div>
                    
                </div>

                <div className="row mb-3">          
                    <div className="col-md-6">
                        <label>Special Skill</label>
                        <select className="form-select" onChange={(ev)=>{
                            setSkill(ev.target.value);
                            if (ev.target.value.length>0) {
                                setSkillerr(false);
                            }
                        }} value={skill} >
                        
                        <option value={""}>--Select User Skill--</option>  
                        {userskill.map((e)=>
                        <option key={e.skill_id} value={e.skill_id}>{e.skill_name}</option>
                        )}                     
                        {/* <option value="Student">Student</option>
                        <option value="Service">Service</option>
                        <option value="Business">Business</option>     
                        <option value="Self-Employmment">Self-Employmment</option>   
                        <option value="Otherss">Others</option> */}
                        </select>
                        {/* <input type="text" className="form-control" value={skill} placeholder="Special Skill" onChange={(ev)=>{
                            setSkill(ev.target.value);
                            if (ev.target.value.length>0) {
                                setSkillerr(false);
                            }
                        }} /> */}
                        {skillerr?<div className="text text-danger small">User Skill Required</div>:''}                        
                    </div>

                    <div className="col-md-6">
                        <label>Skin Color</label>
                        <select className="form-select" onChange={(ev)=>{
                            setSkincolor(ev.target.value);
                            if (ev.target.value.length>0) {
                                setSkincolorerr(false);
                            }
                        }} value={skincolor} >
                        
                            <option value={""}>--Select Skin Color--</option>                       
                            <option>Fair</option>
                            <option>Wheatish</option>
                            <option>Dark</option>                            
                        </select>
                        {skincolorerr?<div className="text text-danger small">Skin Color Required</div>:''}
                    </div>

                    
                </div>




                <div className="row mb-3">          
                    <div className="col-md-6">
                        <label>Cast</label>
                        <select className="form-select" onChange={(ev)=>{
                            setCastid(ev.target.value);
                            if (ev.target.value>0) {
                                setCastiderr(false);
                            }
                        }} value={castid}>
                        
                            <option value={""}>--Select Cast--</option> 
                        {castarr.map((e)=>
                            // <optgroup label={e.pcast_name} key={e.pcast_id}>
                                <option key={e.cast_id} value={e.cast_id}>{e.cast_name}</option>
                            // </optgroup>
                        )}      
                        </select>
                        {castiderr?<div className="text text-danger small">Caste Required</div>:''}

                    </div>
                    <div className="col-md-6">
                        <label>Tribe</label>
                        <select className="form-select" onChange={(ev)=>{
                            setTribeid(ev.target.value);
                            if (ev.target.value>0) {
                                setTribeiderr(false);
                            }
                        }} value={tribeid}>
                            <option value={""}>--Select Tribe--</option>

                            {tribearr.map((t)=>
                            <option value={t.tribe_id} key={t.tribe_id}>{t.tribe_name}</option>
                            )}                            
                           
                        </select>
                        {tribeiderr?<div className="text text-danger small">Tribe Required</div>:''}
                    </div>                    
                </div>
                

                <div className="row mb-3">          
                    <div className="col-md-6">
                        <label>Clan</label>
                        {/* <select className="form-select" onChange={(ev)=>{
                            setClanid(ev.target.value);
                            if (ev.target.value>0) {
                                setClaniderr(false);
                            }
                        }} value={clanid}>
                            <option value={""}>--Select Clan--</option>

                            {clanarr.map((cl)=>
                            <option value={cl.clan_id} key={cl.clan_id}>{cl.clan_name}</option>
                            )}                            
                           
                        </select> */}
                        <input type="text" class="form-control" placeholder="Clan Name" onChange={(ev)=>{
                            setClanid(ev.target.value);
                            if (ev.target.value>0) {
                                setClaniderr(false);
                            }
                        }} value={clanid} />
                        {claniderr?<div className="text text-danger small">Clan Required</div>:''}
                        
                    </div>
                    <div className="col-md-6">
                        <label>Rashi</label>
                        <select className="form-select" onChange={(ev)=>{
                            setRashiid(ev.target.value);
                            if (ev.target.value>0) {
                                setRashiiderr(false);
                            }
                        }} value={rashiid}>
                            <option value={""}>--Select Rashi--</option>

                            {rashiarr.map((r)=>
                            <option value={r.rashi_id} key={r.rashi_id}>{r.rashi_name}</option>
                            )}                            
                           
                        </select>
                        {rashiiderr?<div className="text text-danger small">Rashi Required</div>:''}
                    </div>                    
                </div>
                

                <div className="row mb-3">          
                    <div className="col-md-6">
                        <label>Gon</label>
                        <select className="form-select" value={gonid} onChange={(ev)=>{
                            setGonid(ev.target.value);
                            if (ev.target.value>0) {
                                setGoniderr(false);
                            }
                        }} >
                            <option value={""}>--Select Gon--</option>
                          
                            {gonarr.map((r)=>
                            <option value={r.gon_id} key={r.gon_id}>{r.gon_name}</option>
                            )} 
                          
                        </select>
                        {goniderr?<div className="text text-danger small">Gon Required</div>:''}
                        
                    </div>
                    <div className="col-md-6">
                        <label>Logno</label>
                        <select className="form-select" value={lognoid} onChange={(ev)=>{
                            setLognoid(ev.target.value);
                            if (ev.target.value>0) {
                                setLognoiderr(false);
                            }
                        }} >
                            <option value={""}>--Select Logno--</option>
                           
                            {lognoarr.map((r)=>
                            <option value={r.logno_id} key={r.logno_id}>{r.logno_name}</option>
                            )} 
                            
                        </select>
                        {lognoiderr?<div className="text text-danger small">Logno Required</div>:''}
                    </div>                    
                </div>

                <div className="row mb-3">          
                    <div className="col-md-6">
                     <label>Height (CM)</label>
                         <input type="text" className="form-control" value={height}  placeholder="Height (Cm)" onChange={(ev)=>{
                            setHeight(ev.target.value);
                            if (ev.target.value.length>0) {
                                setHeighterr(false);
                            }
                        }} />
                        {heighterr?<div className="text text-danger small">Height Required</div>:''}
                    
                    </div>
                    <div className="col-md-6">
                        <label>Weight (KG)</label>
                        <input type="text" className="form-control" value={weight} placeholder="Weight (Kg)" onChange={(ev)=>{
                            setWeight(ev.target.value);
                            if (ev.target.value.length>0) {
                                setWeighterr(false);
                            }
                        }} />
                        {weighterr?<div className="text text-danger small">Weight Required</div>:''}
                    </div>
                </div>

                <div className="row mb-3">          
                    <div className="col-md-6">
                         <label>Religion</label>
                        <select className="form-select" onChange={(ev)=>{
                            setReligion(ev.target.value);
                            if (ev.target.value.length>0) {
                                setReligionerr(false);
                            }
                        }} value={religion}>
                            <option value={""}>--Select Religion--</option>
                            {religionarr.map((e)=>
                            <option value={e.religion_id} key={e.religion_id}>{e.religion_name}</option>
                            )}
                            {/* <option value={""}>--Select Religion--</option>

                            <option value={"Hinduism"}>Hinduism</option>
                            <option value={"Islam"}>Islam</option>
                            <option value={"Christian"}>Christian</option>
                            <option value={"Buddhism"}>Buddhism</option>
                            <option value={"Punjubi"}>Punjubi</option> */}
                        </select>
                        {religionerr?<div className="text text-danger small">Religion Required</div>:''}

                    </div>
                    <div className="col-md-6">
                        <label>Community</label>
                        <select className="form-select" onChange={(ev)=>{
                            setCommunity(ev.target.value);
                            if (ev.target.value.length>0) {
                                setCommunityerr(false);
                            }
                        }} value={community} >
                            <option value={""}>--Select Community--</option>
                            <option value={"General"}>General</option>
                            <option value={"ST"}>ST</option>
                            <option value={"SC"}>SC</option>
                            <option value={"OBC"}>OBC</option>
                        </select>
                        {communityerr?<div className="text text-danger small">Community Required</div>:''}
                    </div>                    
                </div>

                <div className="row mb-3">          
                    <div className="col-md-6">
                        <label>Date of Birth</label>
                       <input type="date" id="birthday" placeholder="dd-mm-yyyy" className="form-control" value={dob} onChange={(ev)=>{
                            setDob(ev.target.value);
                            if (ev.target.value.length>0) {
                                setDoberr(false);
                            }
                        }} />
                        {doberr?<div className="text text-danger small">Date of Birth Required</div>:''}
                   
                    </div>
                    <div className="col-md-6">
                        <label>Place of Birth </label>
                         <input type="text" className="form-control" value={pob} placeholder="Place of Birth" onChange={(ev)=>{
                            setPob(ev.target.value);
                            if (ev.target.value.length>0) {
                                setPoberr(false);
                            }
                        }} />
                        {poberr?<div className="text text-danger small">Place of Birth Required</div>:''}                         
                    </div>                    
                </div>

                <div className="row mb-3">          
                    <div className="col-md-6">
                        <label>Time of Birth</label>
                       <input type="time" id="birthday" className="form-control" value={tob} onChange={(ev)=>{
                            setTob(ev.target.value);
                            if (ev.target.value.length>0) {
                                setToberr(false);
                            }
                        }} />
                        {toberr?<div className="text text-danger small">Time of Birth Required</div>:''}                      
                    </div>
                    <div className="col-md-6">
                        <label className="display-block w-100 pb-3" >Mangalik</label>

                        <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="Yes" checked={ismangalikyes} onChange={(ev)=>{
                            setMangalik(ev.target.value);

                            if (ev.target.value=="Yes") {
                                setIsmangalikyes(true);
                                setIsmangalikno(false);
                            }
                        }} />
                        <label className="form-check-label" for="inlineRadio1">Yes</label>
                        </div>

                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="No" checked={ismangalikno} onChange={(ev)=>{
                            setMangalik(ev.target.value);

                            if (ev.target.value=="No") {
                                setIsmangalikyes(false);
                                setIsmangalikno(true);
                            }
                        }} />
                        <label className="form-check-label" for="inlineRadio2">No</label>
                        </div> 
                                           
                    </div>

                    
                                       
                </div>


                <div className="row mb-3">          
                    <div className="col-md-6">
                    <label className="display-block w-100 pb-3" >Initiation</label>                    

                        <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="InitiationOptions" id="inlineRadio1" value="Yes" checked={initiationyes} onChange={(ev)=>{
                            setInitiation(ev.target.value);

                            if (ev.target.value=="Yes") {
                                setInitiationyes(true);
                                setInitiationno(false);
                                setInitiationerr(false);
                            }
                            }} />
                        <label className="form-check-label" for="inlineRadio1">Yes</label>
                        </div>

                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="InitiationOptions" id="inlineRadio2" value="No" checked={initiationno} onChange={(ev)=>{
                            setInitiation(ev.target.value);

                            if (ev.target.value=="No") {
                                setInitiationyes(false);
                                setInitiationno(true);
                                setInitiationerr(false);
                            }
                            }}  />
                            
                            <label className="form-check-label" for="inlineRadio2">No</label>
                            {initiationerr?<div className="text text-danger small">Select Initiation</div>:''} 
                        </div>
                      
                       
                    </div>
                    <div className="col-md-6">
                        <label className="display-block w-100 pb-3" >Diet</label>

                        

                        <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="diet" id="inlineRadio1" value="Veg" checked={userdietyes} onChange={(ev)=>{
                            setUserdiet(ev.target.value);

                            if (ev.target.value=="Veg") {
                                setUserdietyes(true);
                                setUserdietno(false);
                                setUserdieterr(false);
                            }
                        }}  />
                        <label className="form-check-label" for="inlineRadio1">Veg</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="diet" id="inlineRadio2" value="Non Veg" checked={userdietno} onChange={(ev)=>{
                            setUserdiet(ev.target.value);

                            if (ev.target.value=="Non Veg") {
                                setUserdietyes(false);
                                setUserdietno(true);
                                setUserdieterr(false);
                            }
                            }}  />
                            <label className="form-check-label" for="inlineRadio2">Non Veg</label>
                            {userdieterr?<div className="text text-danger small">Select Diet</div>:''} 
                        </div>
                      
                    
                    </div>

                    
                                       
                </div>

                <div className="row mb-3">          
                    <div className="col-md-6">
                    <label className="display-block w-100 pb-3" >Horoscope File <i class="fa fa-exclamation-circle" onClick={()=>{
                            openModal();
                        }}></i></label>  
                    <input type="file" className="form-control" id="horoscopefile" onChange={(ev)=>{
                        setkusthifile(ev.target.files[0]);
                    }}/>
                    {personalinfo.kusthi_file!=null?
                        <img src="https://www.suvivah.org/suvivah/upload_kusthi_file/file.png" style={{width: '75px', height: '75px', cursor: 'pointer'}} onClick={()=>{
                            window.open("https://www.suvivah.org/suvivah/upload_kusthi_file/"+personalinfo.kusthi_file, '_blank');
                        }}></img>
                    :
                        ''
                    }
                       
                    </div>
                                 
                </div>



            <div className="row">                
                <div className="col-md-12 text-center">
                   <button type="button" className="btn btn-red px-5" onClick={updatePersonalInformation}>Update</button>
                </div>               
            </div>

            
        </div>

        </div>
    </div>
</div>
</section>

        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >

            <div className="form-group" style={formgroupmodal}>
            জ্যোতিষী দ্বারা যোটক বিচারের সুব্যাবস্থা আছে।
            </div>

            

            <div className="form-group text-center" style={formgroup}>
            <button onClick={async ()=>{
                setIsOpen(false);
            }} className="btn btn-primary">OK</button>
            </div>
        </Modal>


        </>
    );
}

export default PersonalInformationContent;