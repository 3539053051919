
import React, {useState, useEffect} from "react";

import {Link, useNavigate, useParams } from 'react-router-dom';
import DashboardLeftNav from "../inc/DashboardLeftNav";

// import {useNavigate } from 'react-router-dom';

import axios from "axios";

import validator from "validator";



axios.defaults.baseURL='https://suvivah.org/suvivah/api/';

const img_path = "https://suvivah.org/suvivah/";



function DashboardContent() {

    const [progress,setProgress]=useState(0);
    const [loader,setLoader]=useState(false);

    let [basicinfo,setBasicinfo]=useState("");



    let navigate = useNavigate();

    async function showprofile() {


        setProgress(100);
        setLoader(true);

      
        var fd=new FormData();
        fd.append("pid",localStorage.getItem("pid"));
        var resp=await axios.post("https://suvivah.org/suvivah/api/showprofile",fd);
        var data=resp.data;
        // console.log(data);
        setBasicinfo(data);

        setName(data.name);
        setEmail(data.email);
        setGender(data.gender);
        setPhone(data.phone);        
    }

    let [name,setName]=useState("");
    let [email,setEmail]=useState("");
    let [gender,setGender]=useState("");
    let [phone,setPhone]=useState("");
    let [nameerr,setNameerr]=useState(false);
    let [emailerr,setEmailerr]=useState(false);
    let [gendererr,setGendererr]=useState(false);
    let [phoneerr,setPhoneerr]=useState(false);
    
    // let [msg,setMsg]=useState("");
    let [ismsg,setIsmsg]=useState(false);

    async function updatedashboard() {
        var flag=1;

        // alert(name+', '+email+', '+gender+', '+phone)
        setIsmsg(false);
        
        if (name.length==0 || name.trim()=="") {
            setNameerr(true);
            flag=0;
        }
        if (!validator.isEmail(email)) {
            setEmailerr(true);
            flag=0;
        }
        if (gender.length==0) {
            setGendererr(true);
            flag=0;
        }
        if (phone.length<10 || phone.trim()=="") {
            setPhoneerr(true);
            flag=0;
        }

        // alert(name+', '+email+', '+gender+', '+phone)

        if (flag==1) {
            var fd=new FormData();
            fd.append("pid",localStorage.getItem("pid"));
            fd.append("name",name);
            fd.append("email",email);
            fd.append("gender",gender);
            fd.append("phone",phone);

            var resp=await axios.post("https://suvivah.org/suvivah/api/updatedashboard",fd);
            var data=resp.data;

            setIsmsg(true);
        }

        

    }

    useEffect(()=>{
        if (!localStorage.getItem("pid")) {
            let path=`/login`;
            navigate(path);
        }
        showprofile();
    },[])

    return(
        <>

<div className="devider"></div>


<section className="register-row">
<div className="container">
    <div className="row">
        <div className="col-md-3">

            <DashboardLeftNav />

        </div>
        <div className="col-md-9">
            <h2 className="pb-3">Dashboard</h2>
            

        <div className="register_box">
            <div className="row mb-3">
                <div className="col-md-12">
                    {ismsg?<div className="text text-success text-center">Profile Updated!!</div>:''}
                    <h4>Personal Details</h4>

                    
                </div>
                <div className="col-md-12">
                    <h6>Profile ID: {basicinfo.profile_id}</h6>
                </div>
                <div className="col-md-6">
                    <input type="text" className="form-control" value={name} onChange={(ev)=>{
                        setName(ev.target.value);
                        if (ev.target.value.length>0) {
                            setNameerr(false);
                        }
                    }} />
                    {nameerr?<div className="text text-danger text-small">Name Required</div>:''}
                </div>
                <div className="col-md-6">
                    <input type="email" className="form-control" value={email} onChange={(ev)=>{
                        setEmail(ev.target.value);
                        if (validator.isEmail(ev.target.value.length)) {
                            setEmailerr(false);
                        }
                    }} disabled={true} />
                    {emailerr?<div className="text text-danger text-small">Email Required</div>:''}
                </div>                
            </div>
            <div className="row mb-3">          
                <div className="col-md-6">
                    <select className="form-select" value={gender} onChange={(ev)=>{
                        setGender(ev.target.value);
                        if (ev.target.value.length>0) {
                            setGendererr(false);
                        }
                    }}>
                        <option value={""}>Select Gender</option>
                        <option value={"Male"}>Male</option>
                        <option value={"Femaile"}>Female</option>
                    </select>
                    {gendererr?<div className="text text-danger text-small">Select Gender</div>:''}
                </div>
                <div className="col-md-6">
                    <input type="text" className="form-control" maxLength={10} value={phone}  onChange={(ev)=>{
                        setPhone(ev.target.value);
                        if (ev.target.value.length==10) {
                            setPhoneerr(false);
                        }
                    }} disabled={true} />
                    {phoneerr?<div className="text text-danger text-small">Phone Number Required</div>:''}
                </div>
            </div>

            <div className="row">
                <div className="col-md-12 text-center"><button type="button" className="btn btn-red px-5" onClick={updatedashboard}>Update</button></div>
            </div>

            
        </div>

        </div>
    </div>
</div>
</section>


        </>
    );
}

export default DashboardContent;