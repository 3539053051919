
import React, {useState, useEffect} from "react";

import {Link, useNavigate } from 'react-router-dom';
import DashboardLeftNav from "../inc/DashboardLeftNav";

import axios from "axios";
axios.defaults.baseURL='https://suvivah.org/suvivah/api/';


function ChangePasswordContent() {

    let [password,setPassword]=useState("");
    let [password2,setPassword2]=useState("");

    let [passworderr,setPassworderr]=useState("");
    let [password2err,setPassword2err]=useState("");
    
    let [ismsg,setIsmsg]=useState(false);

    let [msgcode,setMsgcode]=useState("");

    async function updatepassword() {
        var flag=1;

        setIsmsg(false);
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
        
        if (password.length<6 || password.trim()=="") {
            setPassworderr(true);
            flag=0;
        }
        if (password2.length<6 || password2.trim()=="") {
            setPassword2err(true);
            flag=0;
        }
        if (password2!==password && (password.length>=6 && password2.length>=6)) {
            setIsmsg(true);
            setMsgcode(2);
            flag=0;
        }

        if(flag==1) {
            var fd=new FormData();
            fd.append("pid",localStorage.getItem("pid"));
            fd.append("password",password);
            var resp=await axios.post("https://suvivah.org/suvivah/api/updatepassword",fd);
            var data=resp.data;
            setMsgcode(1);
            setIsmsg(true);
        }
    }
    
    return(
        <>

        <div className="devider"></div>


        <section className="register-row">
            <div className="container">
                <div className="row">
                    <div className="col-md-3">
                        <DashboardLeftNav />
                    </div>
                    <div className="col-md-9">
                        <h2 className="pb-3">Dashboard</h2>
                        

                    <div class="register_box">
                        <div class="row mb-3">
                            <div className="col-md-12">
                                {msgcode==1?<div className="text text-success text-center">Password Changed!!</div>:msgcode==2?<div className="text text-danger text-center">Password Does Not Matched!!</div>:''}
                                <h4>Change Password</h4>
                            </div>
                            <div class="col-md-6">
                                <input type="text" class="form-control" placeholder="New Password" onChange={(ev)=>{
                                    setPassword(ev.target.value);
                                    if (ev.target.value.length>=6) {
                                        setPassworderr(false);
                                    }
                                }} />
                                {passworderr?<div className="text text-danger small">Minimum 6 digit / alphanumeric password required</div>:''}
                            </div>
                            <div class="col-md-6">
                                <input type="password" class="form-control" placeholder="Confirm Password" onChange={(ev)=>{
                                    setPassword2(ev.target.value);
                                    if (ev.target.value.length>=6) {
                                        setPassword2err(false);
                                    }
                                }} />
                                {password2err?<div className="text text-danger small">Minimum 6 digit / alphanumeric password required</div>:''}
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 text-center"><button type="button" class="btn btn-red px-5" onClick={updatepassword}>Update Password</button></div>
                        </div>

                        
                    </div>

                    </div>
                </div>
            </div>
        </section>


        </>
    );
}

export default ChangePasswordContent;