import React, {useState, useEffect} from "react";

// import React from 'react';
import {Link} from 'react-router-dom';

import axios from "axios";
axios.defaults.baseURL='https://suvivah.org/suvivah/api/';

function DashboardLeftNav() {

    let [activenav,setActivenav]=useState("");
    let [activenav2,setActivenav2]=useState("");
    let [activenav3,setActivenav3]=useState("");
    let [activenav4,setActivenav4]=useState("");
    let [activenav5,setActivenav5]=useState("");
    let [activenav6,setActivenav6]=useState("");
    let [activenav7,setActivenav7]=useState("");
    let [activenav8,setActivenav8]=useState("");
    let [activenav9,setActivenav9]=useState("");

    let [basicinfo,setBasicinfo]=useState("");

    async function getActiveNav() {

        if (window.location.pathname=="/dashboard") {
            setActivenav("active-link");
        } else if (window.location.pathname=="/communication-details") {
            setActivenav2("active-link");
        } else if (window.location.pathname=="/personal-information") {
            setActivenav3("active-link");
        } else if (window.location.pathname=="/health-details") {
            setActivenav4("active-link");
        } else if (window.location.pathname=="/paternal-bibliography") {
            setActivenav5("active-link");
        }  else if (window.location.pathname=="/maternal-bibliography") {
            setActivenav6("active-link");
        }
        else if (window.location.pathname=="/update-profile-image") {
            setActivenav7("active-link");
        }
        else if (window.location.pathname=="/change-password") {
            setActivenav8("active-link");
        }
        else if (window.location.pathname=="/matching-profile") {
            setActivenav9("active-link");
        }

        var fd=new FormData();
        fd.append("pid",localStorage.getItem("pid"));
        var resp=await axios.post("https://suvivah.org/suvivah/api/showprofile",fd);
        var data=resp.data;
        setBasicinfo(data);
    }

    useEffect(()=>{
        getActiveNav();
    },[])

    return(
        <>
            <div className="p-3 text-center">
                <img src={"https://suvivah.org/suvivah/user_pic/"+basicinfo.pic} className="rounded-circle img-fluid profile_img" alt="..." />
                <h5 className="pt-2" style={{fontFamily: 'Arial'}}><span style={{fontSize: 'medium', fontWeight: '700'}}>{basicinfo.name}</span><br/>
                <span style={{fontSize: 'small', fontWeight: '700'}}>(Profile ID : {localStorage.getItem("pid")})</span>
                </h5>
            </div>
            
            <nav className="navbar card leftNav">
                <div className="container">
                    <ul className="navbar-nav">
                        <li className="nav-item"><Link className={"nav-link "+activenav} to="/dashboard">Basic Information</Link></li>
                        <li className="nav-item"><Link className={"nav-link "+activenav2} to="/communication-details">Communication Details</Link></li>
                        <li className="nav-item"><Link className={"nav-link "+activenav3} to="/personal-information">Personal Information</Link></li>
                        <li className="nav-item"><Link className={"nav-link "+activenav4} to="/health-details">Health Details</Link></li>
                        <li className="nav-item"><Link className={"nav-link "+activenav5} to="/paternal-bibliography">Paternal Bibliography</Link></li>
                        <li className="nav-item"><Link className={"nav-link "+activenav6} to="/maternal-bibliography">Maternal Bibliography</Link></li>
                        <li className="nav-item"><Link className={"nav-link "+activenav7} to="/update-profile-image">Update Profile Image</Link></li>
                        <li className="nav-item"><Link className={"nav-link "+activenav8} to="/change-password">Change Password</Link></li>
                        {/* <li className="nav-item"><Link className={"nav-link "+activenav9} to="/matching-profile">Matching Profile</Link></li> */}
                    </ul>
                </div>
            </nav>

</>

);
}

export default DashboardLeftNav;