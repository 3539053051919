
import React, {useState, useEffect} from "react";

import axios from "axios";
import parse from 'html-react-parser';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';


axios.defaults.baseURL='https://suvivah.org/api/';



function Testimonials() {

    

      const TestimonialsSlider = {        

        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 2
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 2
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 1
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };

      



    const [welcomecontent, setWelcomecontent] = useState();
    async function getwelcomecontent() {
        var resp=await axios.get("/welcome");
        var data=resp.data;
        setWelcomecontent(data.obj);
        console.log(data.obj);
     }

     
     
    useEffect(() => {
        getwelcomecontent();
    }, [])


    return(
        <>


<section className="py-5 testimonials-row">
<div className="container">
    <div className="row">
        <div className="col-md-12 text-center">
            <header className="header-text">
                {/* <h2 className="mb-2">What Clients <span>Say</span></h2> */}
                <h2 className="mb-2">
                    People's <span>Opinion</span><br/>
                    (মানুষের <span>মতামত</span>)
                </h2>
                <p className="text-center mb-4"><img src="images/header_graphics.png" alt="" className="img-fluid" /></p>
            </header>
        </div>
    </div>


    {/* <Carousel responsive={TestimonialsSlider} arrows={true} showDots={false} autoPlay={true} autoPlaySpeed={1500} infinite="true">

            <div className="testimonialBox">                
                <figure><img src="images/test_img.jpg" className="img-fluid" /></figure>
                <figcaption>
                    <h4>Kamal Das</h4>
                    <h5>President Social Worker</h5>
                    <p className="start_rating">
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star" aria-hidden="true"></i>
                    </p>
                </figcaption>
                <div className="clearfix">
                <p>Nullam consectetur risus nec tellus congue accumsan. Integer maximus cursus facilisis. Morbi congue massa mattis nulla porta, id faucibus dolor cursus. Duis libero sapien, sagittis nec ex rutrum, suscipit consectetur ante. Donec vulputate augue non enim fermentum efficitur.</p>
                <p>Integer pulvinar commodo pulvinar. Morbi egestas leo efficitur, scelerisque eros ut, ullamcorper velit. </p>
                </div>
            </div>

            <div className="testimonialBox">                
                <figure><img src="images/test_img.jpg" className="img-fluid" /></figure>
                <figcaption>
                    <h4>Kamal Das</h4>
                    <h5>President Social Worker</h5>
                    <p className="start_rating">
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star" aria-hidden="true"></i>
                    </p>
                </figcaption>
                <div className="clearfix">
                <p>Nullam consectetur risus nec tellus congue accumsan. Integer maximus cursus facilisis. Morbi congue massa mattis nulla porta, id faucibus dolor cursus. Duis libero sapien, sagittis nec ex rutrum, suscipit consectetur ante. Donec vulputate augue non enim fermentum efficitur.</p>
                <p>Integer pulvinar commodo pulvinar. Morbi egestas leo efficitur, scelerisque eros ut, ullamcorper velit. </p>
                </div>
            </div>

            <div className="testimonialBox">                
                <figure><img src="images/test_img.jpg" className="img-fluid" /></figure>
                <figcaption>
                    <h4>Kamal Das</h4>
                    <h5>President Social Worker</h5>
                    <p className="start_rating">
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star" aria-hidden="true"></i>
                    </p>
                </figcaption>
                <div className="clearfix">
                <p>Nullam consectetur risus nec tellus congue accumsan. Integer maximus cursus facilisis. Morbi congue massa mattis nulla porta, id faucibus dolor cursus. Duis libero sapien, sagittis nec ex rutrum, suscipit consectetur ante. Donec vulputate augue non enim fermentum efficitur.</p>
                <p>Integer pulvinar commodo pulvinar. Morbi egestas leo efficitur, scelerisque eros ut, ullamcorper velit. </p>
                </div>
            </div>

            <div className="testimonialBox">                
                <figure><img src="images/test_img.jpg" className="img-fluid" /></figure>
                <figcaption>
                    <h4>Kamal Das</h4>
                    <h5>President Social Worker</h5>
                    <p className="start_rating">
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star" aria-hidden="true"></i>
                    </p>
                </figcaption>
                <div className="clearfix">
                <p>Nullam consectetur risus nec tellus congue accumsan. Integer maximus cursus facilisis. Morbi congue massa mattis nulla porta, id faucibus dolor cursus. Duis libero sapien, sagittis nec ex rutrum, suscipit consectetur ante. Donec vulputate augue non enim fermentum efficitur.</p>
                <p>Integer pulvinar commodo pulvinar. Morbi egestas leo efficitur, scelerisque eros ut, ullamcorper velit. </p>
                </div>
            </div>

            <div className="testimonialBox">                
                <figure><img src="images/test_img.jpg" className="img-fluid" /></figure>
                <figcaption>
                    <h4>Kamal Das</h4>
                    <h5>President Social Worker</h5>
                    <p className="start_rating">
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star" aria-hidden="true"></i>
                    </p>
                </figcaption>
                <div className="clearfix">
                <p>Nullam consectetur risus nec tellus congue accumsan. Integer maximus cursus facilisis. Morbi congue massa mattis nulla porta, id faucibus dolor cursus. Duis libero sapien, sagittis nec ex rutrum, suscipit consectetur ante. Donec vulputate augue non enim fermentum efficitur.</p>
                <p>Integer pulvinar commodo pulvinar. Morbi egestas leo efficitur, scelerisque eros ut, ullamcorper velit. </p>
                </div>
            </div>

            <div className="testimonialBox">                
                <figure><img src="images/test_img.jpg" className="img-fluid" /></figure>
                <figcaption>
                    <h4>Kamal Das</h4>
                    <h5>President Social Worker</h5>
                    <p className="start_rating">
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star" aria-hidden="true"></i>
                    <i class="fa fa-star" aria-hidden="true"></i>
                    </p>
                </figcaption>
                <div className="clearfix">
                <p>Nullam consectetur risus nec tellus congue accumsan. Integer maximus cursus facilisis. Morbi congue massa mattis nulla porta, id faucibus dolor cursus. Duis libero sapien, sagittis nec ex rutrum, suscipit consectetur ante. Donec vulputate augue non enim fermentum efficitur.</p>
                <p>Integer pulvinar commodo pulvinar. Morbi egestas leo efficitur, scelerisque eros ut, ullamcorper velit. </p>
                </div>
            </div>

    </Carousel> */}

<div className="testimonialBox text-center" style={{fontSize: 'x-large'}}>Coming Soon</div>




   


</div>
</section>













        </>
    );
}

export default Testimonials;