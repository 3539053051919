import React  from 'react';

import AboutContent from "../content/AboutContent";

import Navbar from "../inc/Navbar";
import Footer from "../inc/Footer";

function About() {
    return(
        <>
        <Navbar />
        <AboutContent/>
        <Footer />        
        </>
    );
}

export default About;