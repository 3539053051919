
import React, {useState, useEffect} from "react";
import axios from 'axios';
import validator from 'validator';
import {Link, useNavigate } from 'react-router-dom';




function RegisterContent() {
    let navigate = useNavigate();

    let [username,setUsername] = useState("");
    let [useremail,setUseremail] = useState("");
    let [userphone,setUserphone] = useState("");
    let [usergender,setUsergender] = useState("");
    let [userpassword,setUserpassword] = useState("");
    let [userpassword2,setUserpassword2] = useState("");


    let [usernameerr,setUsernameerr] = useState(false);
    let [useremailerr,setUseremailerr] = useState(false);
    let [userphoneerr,setUserphoneerr] = useState(false);
    let [usergendererr,setUsergendererr] = useState(false);
    let [userpassworderr,setUserpassworderr] = useState(false);
    let [userpassword2err,setUserpassword2err] = useState("");


    let [flagmsg,setFlagmsg]=useState(false);
    let [msg,setMsg]=useState("");
    let [msgcode,setMsgcode]=useState("");
    let [msgcolor,setMsgcolor]=useState("");
    let [btnreg,setBtnreg]=useState(false);




    async function userregister() {
        var flag=1;

        if (username.length==0 || username.trim()=="") {
            setUsernameerr(true);
            flag=0;
        }
        if (userphone.length<10 || userphone.trim()=="") {
            setUserphoneerr(true);
            flag=0;
        }
        // if (!validator.isEmail(useremail)) {
        //     setUseremailerr(true);
        //     flag=0;
        // }

        if (usergender.length==0 || usergender.trim()=="") {
            setUsergendererr(true);
            flag=0;
        }
       
        if (userpassword.length<6 || userpassword!=userpassword2) {
            setUserpassworderr(true);
            flag=0;
        }
        if (userpassword2.length<6 || userpassword2.trim()=="" || userpassword!=userpassword2) {
            setUserpassword2err(true);
            flag=0;
        }

        

     
        if (flag==1) {
            let profileid='SV'+Math.floor((Math.random() * 999999999) +1);

            var fd=new FormData();
            fd.append("profile_id",profileid);
            fd.append("name",username);
            fd.append("email",useremail);
            fd.append("phone",userphone);
            fd.append("gender",usergender);          
            fd.append("password",userpassword);           

            var resp=await axios.post("https://suvivah.org/suvivah/api/registerstep1",fd);
            var data=resp.data;
            // alert(success);

            if (data.msgcode==1) {
                setBtnreg(true);
                window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });
                // setBtnreg(true);
                setFlagmsg(true);
                setMsg(data.msg);
                setMsgcolor(data.msgcolor);
                // localStorage.setItem("profile_id",data.profileid);
                localStorage.setItem("profile_id",data.profile_id);
                localStorage.setItem("userregstep",1);
                localStorage.setItem("email",useremail);
                localStorage.setItem("phone",userphone);
                localStorage.setItem("password",userpassword);
                setTimeout(() => {
                    let path=`/register2`;
                    navigate(path);
                }, 2000);
            } else if (data.msgcode==2) {
                window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });
                setFlagmsg(true);
                setMsg(data.msg);
                setMsgcolor(data.msgcolor);
                
            } else if (data.msgcode==3) {
                window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });
                setFlagmsg(true);
                setMsg(data.msg);
                setMsgcolor(data.msgcolor);
                
            } else if (data.msgcode==4) {
                window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });
                setFlagmsg(true);
                setMsg(data.msg);
                setMsgcolor(data.msgcolor);
                
            }
            
        }
       
        

        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    useEffect(()=>{
        if (localStorage.getItem("pid")) {
            let path=`/dashboard`;
            navigate(path);
        }
        // localStorage.removeItem("profile_id");
        if (localStorage.getItem("profile_id") && localStorage.getItem("userregstep")==1) {
            let path=`/register2`;
            navigate(path);
        }
        // console.log('profile_id : '+localStorage.getItem("profile_id") +', '+localStorage.getItem("userregstep"));
        

    },[])



    return(
        <>

        
<div className="devider"></div>


<section className="register-row">
<div className="container">
    <div className="row">
        <div className="col-md-12">
            <header>
                <h2 className="text-center mb-4">Register Now</h2>
                <h5 class="text-center mb-4">
                    <span className="bg-primary py-1 px-3 text-light" >Step 1</span>
                    <span className="bg-white py-1 px-3 text-secondary bg-opacity-50" >Step 2</span>
                    
                </h5>
            </header>
        </div>
    </div>

    {flagmsg?<div className={msgcolor}>{msg}</div>:''}

    <div className="row">
        <div className="col-md-8 offset-md-2">
                <div className="register_box">
            <div className="row mb-3">
                <div className="col-md-6">
                     <input type="text" className="form-control"  placeholder="Enter Full Name" 
                     onChange={(ev)=>{
                        setUsername(ev.target.value)
                        if (ev.target.value.length>1) {
                            setUsernameerr(false);
                        }
                    }} />
                    {usernameerr?<div className='text text-danger small'>Full Name Required</div>:''}


                </div>
                <div className="col-md-6">
                    <input type="email" className="form-control" id="email" placeholder="Enter Email (Optional)" onChange={(ev)=>{
                        setUseremail(ev.target.value)
                        if (ev.target.value.length>1) {
                            setUseremailerr(false);
                        }
                    }} />
                    {useremailerr?<div className='text text-danger small'>User Email Required</div>:''}
                </div>
                
            </div>

            <div className="row mb-3">          
                <div className="col-md-6">
                     <select className="form-select" onChange={(ev)=>{
                            setUsergender(ev.target.value);
                            if (ev.target.value.length>0) {
                                setUsergendererr(false);
                            }
                        }}>
                          <option value={""}>Select Gender</option>
                          <option value={"Male"}>Male</option>
                          <option value={"Female"}>Female</option>
                        </select> 
                        {usergendererr?<div className='text text-danger small'>User Gender Required</div>:''}
                </div>
                <div className="col-md-6">
                    <input type="text" className="form-control" maxLength={10} id="email" placeholder="Enter Phone" onChange={(ev)=>{
                        setUserphone(ev.target.value)
                        if (ev.target.value.length>1) {
                            setUserphoneerr(false);
                        }
                    }} />
                     {userphoneerr?<div className='text text-danger small'>User Phone Required</div>:''}

                </div>
            </div>

            <div className="row mb-3">
                <div className="col-md-6">
                     <input type="password" className="form-control"  placeholder="Enter Password" onChange={(ev)=>{
                                setUserpassword(ev.target.value);
                                if (ev.target.value.length>=6 && ev.target.value==userpassword2) {
                                    setUserpassworderr(false);
                                }
                            }} /> 
                    {userpassworderr?<div className='text text-danger small'>Minimum 6-characters password Required</div>:''}
                </div>
                <div className="col-md-6">
                    <input type="password" className="form-control" id="email" placeholder="Enter Confirm Password" onChange={(ev)=>{
                                setUserpassword2(ev.target.value);
                                if (ev.target.value.length>=6 && ev.target.value==userpassword) {
                                    setUserpassworderr(false);
                                }
                            }} /> 
                    {userpassworderr?<div className='text text-danger small'>Both Password doesn't match</div>:''}
                </div>               
            </div>

            <div className="row">                
                <div className="col-md-12 text-center">
                   <button type="button" className="btn btn-red px-5 t" onClick={userregister} disabled={btnreg} >Register</button>
                </div>               
            </div>

            </div>
        </div>

    </div>
</div>
</section>



        </>
    );
}

export default RegisterContent;