import React  from 'react';

import ContactUsContent from "../content/ContactUsContent";

import Navbar from "../inc/Navbar";
import Footer from "../inc/Footer";

function ContactUs() {
    return(
        <>
        <Navbar />
        <ContactUsContent/>
        <Footer />        
        </>
    );
}

export default ContactUs;