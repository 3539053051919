
import React, {useState, useEffect} from "react";
import {Link} from 'react-router-dom';
import axios from "axios";
axios.defaults.baseURL='https://suvivah.org/suvivah/api/';



function FaqsContent() {

    if (!localStorage.getItem("lang")) {
        localStorage.setItem("lang","English");
    }

    let [faq,setFaq]=useState([]);
    async function getfaqcontent() {
        var fd=new FormData();
        fd.append("lang",localStorage.getItem("lang"));
        var resp=await axios.post("https://suvivah.org/suvivah/api/getfaq",fd);
        var data=resp.data;
        setFaq(data.obj);
        // console.log(data.obj.content);
     }

    useEffect(() => {
        getfaqcontent();
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }, [])
    return(
        <>


<div className="devider"></div>
<section className="register-row">
    <div className="container">
        <div className="row">
            <div className="col-md-12">
                <header className="mb-0 text-center">
                    <h2 className="mb-2" style={{fontSize: 'x-large'}}>FAQ's (Frequestly Ask Questions)</h2>                    
                </header>
            </div>
            <div className="col-md-12 langueWrap text-center">
                <header>
                {localStorage.getItem("lang")==="English"?
                <div>
                    <strong>
                        <span>English</span>
                    </strong>
                    &nbsp; | &nbsp;
                    <span>
                        <Link to="#" onClick={()=>{
                            localStorage.setItem("lang","Bengali");
                            getfaqcontent();
                        }}>বাংলা</Link>
                    </span>
                </div>
                :
                <div>
                    {/* <strong> */}
                        <span>
                            <Link to="#" onClick={()=>{
                                localStorage.setItem("lang","English");
                                getfaqcontent();
                            }}>English</Link>
                        </span>
                    {/* </strong> */}
                    &nbsp; | &nbsp;
                    <strong>
                        <span>বাংলা</span>
                    </strong>
                </div>
                }
                </header>
            </div>
        </div>

        <div className="row">
            <div className="col-md-2"></div>
            <div className="col-md-8">
            <section className="accordion-section clearfix mt-3" aria-label="Question Accordions">
                <div className="container">
                
                    <div className="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                        {faq.map((e)=>
                        <div className="panel panel-default" key={e.id}>
                        <div className="panel-heading p-3 mb-3" role="tab" id={"heading"+e.id}>
                            <h3 className="panel-title">
                            <a className="collapsed" role="button" title="" data-toggle="collapse" data-parent="#accordion" href={"#collapse"+e.id} aria-expanded="true" aria-controls={"collapse"+e.id}>
                                {e.question}
                            </a>
                            </h3>
                        </div>
                        <div id={"collapse"+e.id} className="panel-collapse collapse" role="tabpanel" aria-labelledby={"heading"+e.id}>
                            <div className="panel-body px-3 mb-4">
                            {e.answer}
                            </div>
                        </div>
                        </div>
                        )}
                        
                    </div>
                
                </div>
            </section>
            </div>
            <div className="col-md-2"></div>
        </div>


    </div>
</section>


        </>
    );
}

export default FaqsContent;