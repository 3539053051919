import React  from 'react';

import RegisterContent from "../content/RegisterContent";

import Navbar from "../inc/Navbar";
import Footer from "../inc/Footer";

function Register() {
    return(
        <>
        <Navbar />
        <RegisterContent/>
        <Footer />        
        </>
    );
}

export default Register;