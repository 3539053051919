import React  from 'react';

import ThankyouContent from "../content/ThankyouContent";

import Navbar from "../inc/Navbar";
import Footer from "../inc/Footer";

function Thankyou() {
    return(
        <>
        <Navbar />
        <ThankyouContent/>
        <Footer />        
        </>
    );
}

export default Thankyou;