
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';






function Banner() {

    const homeBanner = {  
        superLargeDesktop: {
          // the naming can be any, depends on you.
          breakpoint: { max: 4000, min: 3000 },
          items: 1
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 1
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 1
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };



    return(
      <>

        <div class="bannerWrapper">

          <div class="bannerSliderBox">
              
              <Carousel responsive={homeBanner} arrows={false} showDots={true} autoPlay={true} autoPlaySpeed={2000} infinite="true" >
                <div><img src="images/banner1.jpg" alt="" width="img-fluid" /></div>
                <div><img src="images/banner2.jpg" alt="" width="img-fluid" /></div>
                <div><img src="images/banner3.jpg" alt="" width="img-fluid" /></div>
              </Carousel>
              
          </div>
          <img src="images/banner_frame.png" alt="" class="banner_frame" />
        </div>

      </>
  );
}

export default Banner;