import React, {useState, useEffect} from "react";
import {Link} from 'react-router-dom';

// For Modal
import Modal from 'react-modal';
import axios from "axios";
// For Modal


import {useNavigate } from 'react-router-dom';

function Footer() {

    let navigate = useNavigate();

    var [securitykey,setSecuritykey]=useState("");
    var [phone,setPhone]=useState("");
    var [securitykeyerr,setSecuritykeyerr]=useState(false);

    var [msg,setMsg]=useState("");
    var [phoneerr,setPhoneerr]=useState(false);
    var [msgphone,setMsgphone]=useState("");

    // For Modal
    const customStyles = {
        content: {
          top: '50%',
          // left: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        //   background: 'red',
        //   color: '#fff',
        },
    };

    const formgroup={
        margin: '10px',
        fontWeight: 700
    };

    const formgroupphone={
        margin: '10px',
        fontWeight: 700,
        display: 'none',
    };

    const formgroupmodal={
        padding: '10px',
        fontWeight: 700,
        border: '0px solid #b9b4cf',
        borderRadius: '4px',
        marginBottom: '5px'
    };

    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }

    let [ismodalalert,setIsmodalalert]=useState("");

    // For Modal

    return(
        <>

<footer class="text-white">

<div class="container py-4">
     <div class="row">
         <div class="col-md-6 ft_text">
            {/* <h2><img src="images/ft_logo.png" alt="" class="fluid-img" /></h2> */}
            <h2><img src="images/logo.png" alt="" class="fluid-img" /></h2>
            <p style={{fontSize: '0.9em'}}>'Made for each other' is commonly used when but beat marriage is effected. This endeavour of ours' is just a attempt to achieve this aim. Therefore, this is solely our mission and vision.</p>
         </div>
         <div class="col-md-3 ft_link">
            <h2>Important</h2>
            <ul style={{fontSize: '0.9em'}}>
                <li><Link to="/">Home</Link></li>
                <li><Link to="/about">About Us</Link></li>
                <li><Link to="/success-story">Success Story </Link></li>
                <li><Link to="/faqs">FAQs</Link></li>
                <li><Link to="/contact-us">Contact Us</Link></li>
                <li><Link to="/sitemap">Sitemap</Link></li>
                <li><Link to="#" onClick={()=>{
                    setIsmodalalert(1);
                    openModal();
                }}>Power Link</Link></li>
            </ul>
         </div>
         <div class="col-md-3">
            <h2>Download সুবিবাহ App</h2>
            <Link to="https://play.google.com/" target="_blank"><img src="images/google_play.png" alt="" class="fluid-img" /></Link>

         </div>
     </div>
 </div>

 <div class="ft_bg">
     <div class="container">
         <div class="row">
             <div class="col-md-12 text-center">
                 <p className='copyright_text'>Copyright &copy; 2022 সুবিবাহ.  All rights reserved. Designed &amp; Developed by <a href='https://www.cheapwebguru.com/' target={"_blank"}>Cheap Web Guru</a> </p>
                 <p class="social_icon">
                     <Link to="http://www.facebook.com/"><i class="fa fa-facebook" aria-hidden="true"></i></Link>
                     <Link to="#"><i class="fa fa-twitter" aria-hidden="true"></i></Link>
                     <Link to="#"><i class="fa fa-youtube-play" aria-hidden="true"></i></Link>
                     <Link to="#"><i class="fa fa-linkedin" aria-hidden="true"></i></Link>
                     <Link to="#"><i class="fa fa-google-plus" aria-hidden="true"></i></Link>                      
                 </p>

             </div>
         </div>
     </div>
 </div>

</footer>
        
        {ismodalalert==1?
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >

            <div className="form-group" style={formgroup}>
                <label>INPUT SECURITY KEY :</label>
                <input type="text" className='form-control' onChange={(ev)=>{
                    setSecuritykey(ev.target.value);
                    if (ev.target.value.length>0) {
                        // setIsmodalbtn(false);
                    } else {
                        // setIsmodalbtn(true);
                    }
                }}/>
                {securitykeyerr?
                <div className="text text-danger small">{msg}</div>
                :
                ''
                }

            </div>
            
            <div className="form-group text-center" style={formgroup}>
            <button onClick={async ()=>{
                if (securitykey.length>=6) {
                    setSecuritykeyerr(false);
                    var fd=new FormData();
                    fd.append("securitykey",securitykey);
                    var resp=await axios.post("https://suvivah.org/suvivah/api/securitykeycheck",fd);
                    var data=resp.data;

                    if (data.statcode==1) {
                        setIsmodalalert(2);
                        openModal();
                    } else {
                        setSecuritykeyerr(true);
                        setMsg("Wrong Security Key");
                    }
                    
                } else {
                    setSecuritykeyerr(true);
                    setMsg("Enter Exact Security Key");                    
                }                
            }} className="btn btn-primary">OK</button>
            </div>
        </Modal>
        :ismodalalert==2?
        <Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >

            <div className="form-group" style={formgroup}>
                <label>INPUT VALID PHONE NUMBER :</label>
                <input type="tel" maxLength={10} className='form-control' onChange={(ev)=>{
                    setPhone(ev.target.value);
                }} defaultValue={phone}/>
                {phoneerr?
                <div className="text text-danger small">{msg}</div>
                :
                ''
                }
            </div>

            <div className="form-group text-center" style={formgroup}>
            <button onClick={async ()=>{
                if (phone.length==10) {

                    setPhoneerr(false);
                    var fd=new FormData();
                    fd.append("phone",phone);
                    var resp=await axios.post("https://suvivah.org/suvivah/api/regphonecheck",fd);
                    var data=resp.data;
                    if (data.statcode==1) {

                        localStorage.setItem("pid",data.profileid);
                        let path=`/dashboard`;
                        navigate(path);

                    } else {
                        setPhoneerr(true);
                        setMsg("Wrong Phone Number");
                    }

                    // setPhoneerr(true);
                    // setMsg("Enter Valid Registered Phone Number");
                } else {
                    setPhoneerr(true);
                    setMsg("Enter Valid Registered Phone Number");                    
                }                
            }} className="btn btn-primary">SUBMIT</button>
            </div>
        </Modal>
        :
        ''
        }
        </>
    );
}

export default Footer;