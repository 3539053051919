
import React, {useState, useEffect} from "react";
import axios from 'axios';
import validator from 'validator';
import {Link, useNavigate } from 'react-router-dom';
// For Modal
import Modal from 'react-modal';
// For Modal


function RegisterContent2() {
    let navigate = useNavigate();

    let [address1,setAddress1] = useState("");
    let [address2,setAddress2] = useState("");
    let [city,setCity] = useState("");
    let [district,setDistrict] = useState("");
    let [pincode,setPincode] = useState("");
    let [state,setState] = useState("");
    let [country,setCountry] = useState("");
    let [fathername,setFathername] = useState("");
    let [mothername,setMothername] = useState("");
    let [castid,setCastid] = useState("");
    let [tribeid,setTribeid] = useState("");
    let[clanid,setClanid]=useState("");
    let[rashiid,setRashiid]=useState("");
    let[gonid,setGonid]=useState("");
    let[lognoid,setLognoid]=useState("");
    let[height,setHeight]=useState("");
    let[weight,setWeight]=useState("");
    let[religion,setReligion]=useState("");
    let[community,setCommunity]=useState("");
    let[dateofbirth,setDateofbirth]=useState("");
    let[placeofbirth,setPlaceofbirth]=useState("");
    let[timeofbirth,setTimeofbirth]=useState("");
    let[mangalik,setMangalik]=useState("");

    let [address1err,setAddress1err] = useState(false);
    let [address2err,setAddress2err] = useState(false);
    let [cityerr,setCityerr] = useState(false);
    let [districterr,setDistricterr] = useState(false);
    let [pincodeerr,setPincodeerr] = useState(false);
    let [stateerr,setStateerr] = useState(false);
    let [countryerr,setCountryerr] = useState(false);
    let [fathernameerr,setFathernameerr] = useState(false);
    let [mothernameerr,setMothernameerr] = useState(false);
    let [castiderr,setCastiderr] = useState(false);
    let[heighterr,setHeighterr]=useState(false);
    let[weighterr,setWeighterr]=useState(false);
    let[religionerr,setReligionerr]=useState(false);
    let[communityerr,setCommunityerr]=useState(false);
    let[dateofbirtherr,setDateofbirtherr]=useState(false);
    let[placeofbirtherr,setPlaceofbirtherr]=useState(false);
    let[timeofbirtherr,setTimeofbirtherr]=useState(false);
    let[mangalikerr,setMangalikerr]=useState("");

    let [flagmsg,setFlagmsg]=useState(false);
    let [msg,setMsg]=useState("");
    let [msgcode,setMsgcode]=useState("");
    let [msgcolor,setMsgcolor]=useState("");
    let [btnreg,setBtnreg]=useState(false);

    // For Modal
    const customStyles = {
        content: {
          top: '50%',
          // left: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
    };

    const formgroup={
        margin: '10px',
        fontWeight: 700
    };

    const formgroupmodal={
        padding: '10px',
        fontWeight: 700,
        border: '0px solid #b9b4cf',
        borderRadius: '4px',
        marginBottom: '5px'
    };

    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        subtitle.style.color = '#f00';
    }

    function closeModal() {
        setIsOpen(false);
    }
    // FOr Modal


    async function userregister() {
        var flag=1;

        if (address1.length==0 || address1.trim()=="") {
            setAddress1err(true);
            flag=0;
        }
        if (city.length==0 || city.trim()=="") {
            setCityerr(true);
            flag=0;
        }
        if (pincode.length<1 || pincode.trim()=="") {
            setPincodeerr(true);
            flag=0;
        }
        if (state.length==0 || state.trim()=="") {
            setStateerr(true);
            flag=0;
        }
        if (country.length==0) {
            setCountryerr(true);
            flag=0;
        }
        if (fathername.length==0 || fathername.trim()=="") {
            setFathernameerr(true);
            flag=0;
        }
        if (mothername.length==0 || mothername.trim()=="") {
            setMothernameerr(true);
            flag=0;
        }
        // if (height.length==0 || height.trim()=="") {
        //     setHeighterr(true);
        //     flag=0;
        // }
        // if (weight.length==0 || weight.trim()=="") {
        //     setWeighterr(true);
        //     flag=0;
        // }
        if (religion.length==0) {
            setReligionerr(true);
            flag=0;
        }
        if (community.length==0) {
            setCommunityerr(true);
            flag=0;
        }
        if (dateofbirth.length==0 || dateofbirth.trim()=="") {
            setDateofbirtherr(true);
            flag=0;
        }
        // if (placeofbirth.length==0 || placeofbirth.trim()=="") {
        //     setPlaceofbirtherr(true);
        //     flag=0;
        // }
        // if (timeofbirth.length==0 || timeofbirth.trim()=="") {
        //     setTimeofbirtherr(true);
        //     flag=0;
        // }
        // if (mangalik.length==0) {
        //     setMangalikerr(true);
        //     flag=0;
        // }




        if (flag==1) {
            // alert(localStorage.getItem("profile_id"))
            var fd=new FormData();
            fd.append("profile_id",localStorage.getItem("profile_id"));
            fd.append("addr1",address1);
            fd.append("addr2",address2);
            fd.append("city",city);
            fd.append("pincode",pincode);
            fd.append("state",state);
            fd.append("country",country);
            fd.append("father_name",fathername);
            fd.append("mother_name",mothername);
            fd.append("cast_id",castid);
            fd.append("tribe_id",tribeid);
            fd.append("clan_id",clanid);
            fd.append("rashi_id",rashiid);
            fd.append("gon_id",gonid);
            fd.append("logno_id",lognoid);
            fd.append("height",height);
            fd.append("weight",weight);
            fd.append("religion",religion);
            fd.append("community",community);
            fd.append("dateofbirth",dateofbirth);
            fd.append("placeofbirth",placeofbirth);
            fd.append("timeofbirth",timeofbirth);
            fd.append("mangalik",mangalik);
            
            var resp=await axios.post("https://suvivah.org/suvivah/api/registerstep2",fd);
            var data=resp.data;

            
            
            if (data.msgcode==1) {
                // setBtnreg(true);
                // alert("Hello1 : "+localStorage.getItem("profile_id"));
                // alert(localStorage.getItem("profile_id")+'+');
                window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });
                setBtnreg(true);
                setFlagmsg(true);
                setMsg(data.msg);
                setMsgcolor(data.msgcolor);
                setTimeout(() => {
                    // localStorage.removeItem("profile_id");
                    localStorage.removeItem("userregstep");
                    let path=`/thankyou`;
                    navigate(path);
                }, 2000);
            } else {
                // alert("Hello2 : "+localStorage.getItem("profile_id"));
                // alert(localStorage.getItem("profile_id")+',');
                window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                });
                
                //setSellerdisplaynameerr(true);
               // setSellerdisplaynameerrtxt(data.msg);
            }


        }

        

    }

    let [castarr,setCastarr]=useState([]);
    let [tribearr,setTribearr]=useState([]);
    let [clanarr,setClanarr]=useState([]);
    let [rashiarr,setRashiarr]=useState([]);
    let [gonarr,setGonarr]=useState([]);
    let [lognoarr,setLongoarr]=useState([]);
    let [countryarr,setCountryarr]=useState([]);
    let [religionarr,setReligionarr]=useState([]);

    async function getRegfields() {
        var resp=await axios.get("https://suvivah.org/suvivah/api/reg_cast");
        var data=resp.data;
        console.log(data);
        setCastarr(data);

        var resp2=await axios.get("https://suvivah.org/suvivah/api/reg_other");
        var data2=resp2.data;
        setTribearr(data2.tribe);
        setClanarr(data2.clan);
        setRashiarr(data2.rashi);
        setGonarr(data2.gon);
        setLongoarr(data2.logno);
        // setCountryarr(data2.country);
        setCountryarr(data2.countries);
        setReligionarr(data2.religion);

    }

    let [statearr,setStatearr]=useState([]);

    async function getstatelist(countryid) {
        var fd=new FormData();
        fd.append("countryid",countryid);
        var resp=await axios.post("https://suvivah.org/suvivah/api/statelist",fd);
        var data=resp.data;
        setStatearr(data);
    }
    

    useEffect(()=>{
        if (!localStorage.getItem("profile_id")) {
            let path=`/`;
            navigate(path);
        }
        // console.log('user_id : '+localStorage.getItem("profile_id") +', '+localStorage.getItem("userregstep"));

        getRegfields();

        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });

        if (localStorage.getItem("pid")) {
            let path=`/dashboard`;
            navigate(path);
        }
        
    },[])



    return(
        <>

        
<div class="devider"></div>

<section className="register-row">
<div class="container">
    <div class="row">
        <div class="col-md-12">
            <header>
                <h2 class="text-center mb-4">Register Now</h2>
                <h5 class="text-center mb-4">
                    <span className="bg-white py-1 px-3 text-secondary bg-opacity-50" >Step 1</span>
                    <span className="bg-primary py-1 px-3 text-light" >Step 2</span>
                </h5>
            </header>
        </div>
    </div>

    {flagmsg?<div className={msgcolor}>{msg}</div>:''}

    <div class="row">
        <div class="col-md-8 offset-md-2">
                <div class="register_box">
            <div class="row mb-3">
                <label class="fw-bold">Residential Address</label>
                <div class="col-md-6">
                     <textarea class="form-control" rows="2" id="comment" name="text" placeholder="Address Line 1" onChange={(ev)=>{
                        setAddress1(ev.target.value);
                        if (ev.target.value.length>1) {
                            setAddress1err(false);
                        }
                    }}></textarea>  
                    {address1err?<div className='text text-danger small'>Street Name/Street Number/Flat Number/House Number Required</div>:''}
                </div>
                <div class="col-md-6">
                    <textarea class="form-control" rows="2" id="comment" name="text"  placeholder="Address Line 2 (Optional)"                         
                    onChange={(ev)=>{
                        setAddress2(ev.target.value)
                    }}
                    ></textarea>  
                   
                </div>
                
            </div>

            <div class="row mb-3">          
                <div class="col-md-6">
                     <input type="text" class="form-control" id="City" placeholder="City" 
                     onChange={(ev)=>{
                        setCity(ev.target.value);
                        if (ev.target.value.length>1) {
                            setCityerr(false);
                        }
                    }} />
                     {cityerr?<div className='text text-danger small'>City Required</div>:''} 
                </div>
                <div class="col-md-6">
                    <input type="text" class="form-control" id="District" placeholder="District (Otional)" name="Distric"
                    onChange={(ev)=>{
                        setDistrict(ev.target.value)
                    }} />                    
                </div>
            </div>

            <div class="row mb-4">          
                <div class="col-md-12">
                    <select class="form-select" onChange={(ev)=>{
                            setCountry(ev.target.value);
                            if (ev.target.value.length>0) {
                                setCountryerr(false);
                                getstatelist(ev.target.value);
                            }
                        }}>
                        <option value={""}>--Select Country--</option>
                        {countryarr.map((e)=>
                        <option key={e.id} value={e.id}>{e.name}</option>
                        )}
                    </select>
                    {countryerr?<div className='text text-danger small'>Country Required</div>:''}
                </div>
                
            </div>

             <div class="row mb-3">          
                <div class="col-md-6">
                     <input type="text" class="form-control" placeholder="Pincode" name="Pincode"
                     onChange={(ev)=>{
                        setPincode(ev.target.value);
                        if (ev.target.value.length>1) {
                            setPincodeerr(false);
                        }
                    }} />
                     {pincodeerr?<div className='text text-danger small'>Pincode Required</div>:''} 
                </div>
                <div class="col-md-6">
                    {/* <input type="text" class="form-control" placeholder="State" name="State" 
                     onChange={(ev)=>{
                        setState(ev.target.value);
                        if (ev.target.value.length>1) {
                            setStateerr(false);
                        }
                    }} /> */}
                    <select class="form-select" onChange={(ev)=>{
                            setState(ev.target.value);
                            if (ev.target.value.length>1) {
                                setStateerr(false);
                            }
                        }}>
                        <option value={""}>--Select State--</option>
                        {statearr.map((e)=>
                        // <option key={e.country_id} value={e.country_id}>{e.country_name}</option>
                        <option key={e.id} value={e.id}>{e.name}</option>
                        )}
                    </select>

                     {stateerr?<div className='text text-danger small'>State Required</div>:''} 
                </div>
            </div>

            


            <label class="fw-bold">Personal Information</label>

                <div class="row mb-3">          
                    <div class="col-md-6">
                         <input type="text" class="form-control" placeholder="Father's Name" onChange={(ev)=>{
                        setFathername(ev.target.value);
                        if (ev.target.value.length>1) {
                            setFathernameerr(false);
                        }
                    }} />
                     {fathernameerr?<div className='text text-danger small'>Father Name Required</div>:''} 
                    </div>
                    <div class="col-md-6">
                        <input type="text" class="form-control" placeholder="Mother's Name" onChange={(ev)=>{
                        setMothername(ev.target.value);
                        if (ev.target.value.length>1) {
                            setMothernameerr(false);
                        }
                    }} />
                     {mothernameerr?<div className='text text-danger small'>Mother Name Required</div>:''} 
                    </div>
                </div>

                <div class="row mb-3">          
                    <div class="col-md-6">
                        <select class="form-select" onChange={(ev)=>{
                        setCastid(ev.target.value)
                        }} >
                        
                        {/* <option value={""}>--Select Cast (বর্ণ)--</option>
                        {castarr.map((e)=>
                            // <optgroup label={e.pcast_name} key={e.pcast_id}>
                            // {e.childcast.map((c)=>
                                <option key={e.cast_id} value={e.cast_id}>{e.cast_name}</option>
                            // )}
                            // </optgroup>
                        )} */}

                        <option value={""}>--Select Cast (বর্ণ)--</option>
                        {castarr.map((e)=>
                            // <optgroup label={e.pcast_name} key={e.pcast_id}>
                            // {e.childcast.map((c)=>
                                <option key={e.cast_id} value={e.cast_id}>{e.cast_name}</option>
                            // )}
                            // </optgroup>
                        )}
                            
                        </select>
                    </div>
                    <div class="col-md-6">
                        <select class="form-select" onChange={(ev)=>{
                        setTribeid(ev.target.value)
                        }}>
                            <option value={""}>--Select Tribe (গোত্র)--</option>
                            {tribearr.map((e)=>
                            <option key={e.tribe_id} value={e.tribe_id}>{e.tribe_name}</option>
                            )}
                        </select>
                    </div>                    
                </div>
                

                <div class="row mb-3">          
                    <div class="col-md-6">
                        {/* <select class="form-select" onChange={(ev)=>{
                        setClanid(ev.target.value)
                        }}>
                            <option value={""}>--Select Clan--</option>
                            {clanarr.map((e)=>
                            <option key={e.clan_id} value={e.clan_id}>{e.clan_name}</option>
                            )}
                        </select> */}
                        <input type="text" class="form-control" placeholder="Clan Name (বংশ)" onChange={(ev)=>{
                            setClanid(ev.target.value);
                        }} />
                        
                    </div>
                    <div class="col-md-6">
                        <select class="form-select" onChange={(ev)=>{
                        setRashiid(ev.target.value)
                        }}>
                            <option value={""}>--Select Rashi (রাশি)--</option>
                            {rashiarr.map((e)=>
                            <option key={e.rashi_id} value={e.rashi_id}>{e.rashi_name}</option>
                            )}
                        </select>
                    </div>                    
                </div>
                

                <div class="row mb-3">          
                    <div class="col-md-6">
                        <select class="form-select"  onChange={(ev)=>{
                        setGonid(ev.target.value)
                        }}>
                            <option value={""}>--Select Gon (গন)--</option>
                            {gonarr.map((e)=>
                            <option key={e.gon_id} value={e.gon_id}>{e.gon_name}</option>
                            )}
                        </select>
                        
                    </div>
                    <div class="col-md-6">
                        <select class="form-select" onChange={(ev)=>{
                            setLognoid(ev.target.value)
                        }}>
                            <option value={""}>--Select Logno (লগ্ন)--</option>
                            {lognoarr.map((e)=>
                            <option key={e.logno_id} value={e.logno_id}>{e.logno_name}</option>
                            )}
                        </select>
                    </div>                    
                </div>

                <div class="row mb-3">          
                    <div class="col-md-6">
                         <input type="text" class="form-control" placeholder="Height (Cm)" onChange={(ev)=>{
                        setHeight(ev.target.value);
                        if (ev.target.value.length>1) {
                            setHeighterr(false);
                        }
                    }} />
                     {heighterr?<div className='text text-danger small'>Height Required</div>:''} 
                    </div>
                    <div class="col-md-6">
                        <input type="text" class="form-control" placeholder="Weight (Kg)" onChange={(ev)=>{
                        setWeight(ev.target.value);
                        if (ev.target.value.length>1) {
                            setWeighterr(false);
                        }
                    }} />
                     {weighterr?<div className='text text-danger small'>Weight Required</div>:''} 
                    </div>
                </div>

                <div class="row mb-3">          
                    <div class="col-md-6">
                        <select class="form-select" onChange={(ev)=>{
                        setReligion(ev.target.value);
                        if (ev.target.value.length>1) {
                            setReligionerr(false);
                        }
                    }}>
                        <option value={""}>--Select Religion (জাতি)--</option>
                        {religionarr.map((e)=>
                        <option value={e.religion_id} key={e.religion_id}>{e.religion_name}</option>
                        )}
                            {/* <option value={""}>--Select Religion--</option>
                            <option value={"Hinduism"}>Hinduism</option>
                            <option value={"Islam"}>Islam</option>
                            <option value={"Christian"}>Christian</option>
                            <option value={"Buddhism"}>Buddhism</option>
                            <option value={"Punjubi"}>Punjubi</option> */}
                        </select>
                        {religionerr?<div className='text text-danger small'>Religion Required</div>:''} 
                    </div>
                    <div class="col-md-6">
                        <select class="form-select" onChange={(ev)=>{
                        setCommunity(ev.target.value);
                        if (ev.target.value.length>1) {
                            setCommunityerr(false);
                        }
                    }}>
                            <option value={""}>--Select Community (সম্প্রদায়)--</option>
                            <option value={"General"}>General</option>
                            <option value={"ST"}>ST</option>
                            <option value={"SC"}>SC</option>
                            <option value={"OBC"}>OBC</option>
                        </select>
                        {communityerr?<div className='text text-danger small'>Community Required</div>:''} 
                    </div>                    
                </div>

                <div class="row mb-3">          
                    <div class="col-md-6">
                        <label>Date of Birth</label>
                       <input type="date" id="birthday" class="form-control"  onChange={(ev)=>{
                        setDateofbirth(ev.target.value);
                        if (ev.target.value.length>1) {
                            setDateofbirtherr(false);
                        }
                    }}/>
                    {dateofbirtherr?<div className='text text-danger small'>Date of birth Required</div>:''} 
                    </div>
                    <div class="col-md-6">
                        <label>Place of Birth </label>
                         <input type="text" class="form-control" placeholder="Place of Birth" onChange={(ev)=>{
                        setPlaceofbirth(ev.target.value);
                        if (ev.target.value.length>1) {
                            setPlaceofbirtherr(false);
                        }
                    }} />
                         {placeofbirtherr?<div className='text text-danger small'>Place of birth Required</div>:''} 

                          
                    </div>                    
                </div>

                <div class="row mb-3">          
                    <div class="col-md-6">
                        <label>Time of Birth</label>
                       <input type="time" id="birthday" class="form-control" onChange={(ev)=>{
                        setTimeofbirth(ev.target.value);
                        if (ev.target.value.length>1) {
                            setTimeofbirtherr(false);
                        }
                    }} />
                       {timeofbirtherr?<div className='text text-danger small'>Timeo of birth Required</div>:''} 
                       
                    </div>
                    <div className="col-md-6">
                        <label className="display-block w-100 pb-3" >Mangalik (মাঙ্গলিক) <i class="fa fa-exclamation-circle" onClick={()=>{
                            openModal();
                        }}></i></label>
                        <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="Yes" onChange={(ev)=>{
                            setMangalik(ev.target.value);
                            if (ev.target.value.length>0) {
                                setMangalikerr(false);
                            }
                        }} />
                        <label className="form-check-label" for="inlineRadio1">Yes</label>
                        </div>
                        <div className="form-check form-check-inline">
                            <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="No" onChange={(ev)=>{
                            setMangalik(ev.target.value);
                            if (ev.target.value.length>0) {
                                setMangalikerr(false);
                            }
                        }} />
                        <label className="form-check-label" for="inlineRadio2">No</label>
                        </div>
                        {mangalikerr?<div className='text text-danger small'>Choose One</div>:''} 
                    
                    </div>

                    
                                       
                </div>

            <div class="row">                
                <div class="col-md-12 text-center">
                   <button type="button" class="btn btn-red px-5 t" onClick={userregister} disabled={btnreg} >Register</button>
                </div>               
            </div>

            </div>
        </div>

    </div>
</div>
</section>



<Modal
            isOpen={modalIsOpen}
            onAfterOpen={afterOpenModal}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
        >

            <div className="form-group" style={formgroupmodal}>
            মাঙ্গলিকের সাথে মাঙ্গলিকের বিবাহ চলে অন্যথায় মাঙ্গলিক দোষ কাটানোর সুব্যবস্থা আছে
            </div>

            

            <div className="form-group text-center" style={formgroup}>
            <button onClick={async ()=>{
                setIsOpen(false);
            }} className="btn btn-primary">OK</button>
            </div>
        </Modal>


        </>
    );
}

export default RegisterContent2;