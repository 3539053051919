import React  from 'react';

import ChangePasswordContent from "../content/ChangePasswordContent";

import Navbar2 from "../inc/Navbar2";
import Footer from "../inc/Footer";

function ChangePassword() {
    return(
        <>
        <Navbar2 />
        <ChangePasswordContent/>
        <Footer />        
        </>
    );
}

export default ChangePassword;