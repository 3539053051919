import React  from 'react';

import SuccessStoryContent from "../content/SuccessStoryContent";

import Navbar from "../inc/Navbar";
import Footer from "../inc/Footer";

function SuccessStory() {
    return(
        <>
        <Navbar />
        <SuccessStoryContent/>
        <Footer />        
        </>
    );
}

export default SuccessStory;