
import React, {useState, useEffect} from "react";

import axios from "axios";

import {useNavigate } from 'react-router-dom';
axios.defaults.baseURL='https://suvivah.org/suvivah/api/';



function ThankyouContent() {
    let navigate=useNavigate();

    const [showemail,setShowemail]=useState("");
    const [profileid,setProfileid]=useState("");
    const [showphone,setShowphone]=useState("");
    const [showpassword,setShowpassword]=useState("");

    function getCheckStorage() {
        
        // if (!localStorage.getItem("profile_id")) {
        //     let path=`/`;
        //     navigate(path);
        // }

        if(localStorage.getItem("profile_id")) {
            setProfileid(localStorage.getItem("profile_id"));
            setShowemail(localStorage.getItem("email"));
            setShowphone(localStorage.getItem("phone"));
            setShowpassword(localStorage.getItem("password"));
            // localStorage.removeItem("profile_id");    
        }

        if (localStorage.getItem("profile_id")==null) {
            let path=`/login`;
            navigate(path);
        }

    }

    function removeAllStorage() {
        setTimeout(()=>{
            localStorage.removeItem("profile_id");
            localStorage.removeItem("email");
            localStorage.removeItem("phone");
            localStorage.removeItem("password");
        }, 3000)        
    }

    

    useEffect(()=>{
        // if(!localStorage.getItem("profile_id")) {
        //     let path=`/`;
        //     navigate(path);
        // } else {
        //     localStorage.removeItem("profile_id");
        // }
        getCheckStorage();
        removeAllStorage();
    },[])
    
    return(
        <>

<div class="devider"></div>

<section class="login-row">
<div class="container">
    <div class="row">
        <div class="col-md-12">
            <header>
                <h1 class="text-center mb-4 fw-bold text-uppercase">Thank You!</h1>
            </header>
            
        </div>
    </div>

   

    <div class="row">
        <div class="col-md-10 offset-md-1 text-center">

            <h1 className="">
                <span className="thanks_you_circle"><i className="fa fa-check text-success" aria-hidden="true"></i></span>
            </h1>
        <h5 className=" pt-2">Your profile ID {profileid} has been successfully registered.</h5>    
        {/* {(localStorage.getItem("email")!=null || localStorage.getItem("email").length>1)?
        <div>
            <h5 className="bp-2">An confirmation link has been sent to your registered email id.</h5>
            <h5 className="bp-2">Please check this link to your email inbox or junk email. Click on link and verified your profile.</h5>
        </div>
        :
        <div></div>
        } */}
        
        </div>

    </div>
    <div class="row">
        <div class="col-md-12 register_box mt-4" style={{fontWeight: 700}}>
            <p style={{fontSize: 'large'}}>Registered Phone Number : {showphone}</p>
            <p style={{fontSize: 'large'}}>Password : {showpassword}</p>
            <p className="mt-4" style={{fontSize: 'small',fontWeight: 400}}>[* Note : Please Copy/Note Your Registered Phone Number and Password for Future Login Authentication]</p>
        </div>
    </div>

</div>
</section>




        </>
    );
}

export default ThankyouContent;